import React from 'react';
import { Fragment } from 'react';
import { isMobile } from 'react-device-detect';
import Call from '../../img/call.png';
import Call1 from '../../img/call1.png';

export const PhoneCallButton = (props) => {

  var tel = 'tel: 0731 308 828';
  var telAndreea = 'tel: 0755 802 241';
  var title = 'Apelați autoturisme 1Motor.ro: Adrian';
  var titleAndreea = 'Apelați autoturisme 1Motor.ro: Andreea';

  var button = <a href={tel} title={title}><img src={Call} className="button-thumbnail" alt="Apelați autoturisme 1Motor.ro" /></a>;
  if (props.andreea) {
    button = <a href={telAndreea} title={titleAndreea}><img src={Call1} className="button-thumbnail" alt="Apelați autoturisme 1Motor.ro" /></a>;
  }

  return (
    <Fragment>
      {button} 
      {(!isMobile || (isMobile && props.showPhoneNumber)) &&
        <span>{props.andreea ? telAndreea: tel}</span>
      }
    </Fragment>
  )
}