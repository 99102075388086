import { LOAD_BRANDS, LOAD_CAR_TYPES, LOAD_DEALERS, LOAD_LOCATIONS, LOAD_FUEL_TYPES, LOAD_MODELS, RELOAD_DATA, USER_LOGIN } from "./constants"

export function userLogin(payload) {
  return { type: USER_LOGIN, payload }
};
export function addBrands(payload) {
  return { type: LOAD_BRANDS, payload }
};
export function addModels(payload) {
  return { type: LOAD_MODELS, payload }
};
export function addFuelTypes(payload) {
  return { type: LOAD_FUEL_TYPES, payload }
};
export function addDealers(payload) {
  return {type: LOAD_DEALERS, payload};
};
export function addLocations(payload) {
  return {type: LOAD_LOCATIONS, payload};
};
export function addCarTypes(payload) {
  return {type: LOAD_CAR_TYPES, payload};
};
export function reloadData() {
  return { type: RELOAD_DATA};
};