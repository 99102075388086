import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { loadFuelTypes } from '../../redux';

export const SelectFuelInternal = ({ handleSelectFuel, fuelTypes }) => {
    const { t } = useTranslation(['labels']);
    const [state, setState] = useState({
        fuel: [],
        fuelTypes: []
    });

    useEffect(() =>{
        loadFuelTypes();
        if (fuelTypes?.length > 0) {
            setState({...state, fuelTypes});
        }
    }, [fuelTypes])

    const toggleFuelType = (fuelType) => {
        var fuel = state.fuel;
        if (fuelType) {
            var fuelIndex = fuel.findIndex(f => f.id === fuelType.id);
            if (fuelIndex > -1) {
                fuel.splice(fuelIndex, 1);
            } else {
                fuel.push(fuelType);
            }
        } else {
            // reset
            fuel = [];
        }
        setState({ ...state, fuel });
        handleSelectFuel(fuel);
    }

    return <div class="text-left w-100">
        <h5>{t('fuel_select')}:</h5>
        <div class="list-group">
            <a class="list-group-item" onClick={() => toggleFuelType()}>{state.fuel.length === 0 ? <i class="fa-solid fa-circle-check"></i> : ''}{t('fuel_all')}</a>
            {state.fuelTypes.map(fuelType =>
                <a key={fuelType.id} class="list-group-item" onClick={() => toggleFuelType(fuelType)}>{state.fuel?.includes(fuelType) ? <i class="fa-solid fa-circle-check"></i> : ''}{t(fuelType.label)}</a>
            )}
        </div>

    </div>
}

const mapStateToProps = (state) => {
    return {
        fuelTypes: state.fuelTypes
    }
}

export const SelectFuel = connect(mapStateToProps)(SelectFuelInternal);