import axios from 'axios';
import React, { useState } from 'react';
import Modal from 'react-bootstrap/esm/Modal';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { API } from '../../links';
import { ICar } from '../../models/car';
import { LoggedUser } from '../../redux/LoggedUser';
import { PhoneCallButton } from '../fields';

interface ShareProps {
    car: ICar,
    loggedUser: LoggedUser
}
interface ShareState {
    email: string
}

const CarContactInternal = ({ car, loggedUser }: ShareProps) => {
    const { t } = useTranslation(['labels']);
    const [state, setState] = useState<ShareState>({
        email: ''
    })

    const sendEmail = () => {
        axios({
            method: 'post',
            url: API.SHARE,
            data: { email: state.email, link: window.location.href, car: `${car.brand} ${car.model}` }
        }).then(response => {
            alert(t('sent'))
        });
    }

    return <>
        <div className="container">
            <div className="row">
                <div className="col">
                <PhoneCallButton showPhoneNumber />
                {isMobile && <br />}
                <PhoneCallButton showPhoneNumber andreea />
                </div>
            </div>
            <hr />
            <div className="row">
                <div className="col">
                {`${t('or')}`}
                <br />
                {`${t('send_us_mail')}`}
                <a href="mailto:office@1motor.ro"><button type="button" className="btn btn-primary">office@1motor.ro<i className="fa-regular fa-envelope"></i></button></a>
                </div>
            </div>
            {isMobile &&
                <>
                    <hr />
                    <div className="row">
                        <div className="col">
                            {`${t('or')}`}
                            <br />
                            <a href="https://wa.me/+400731308828"><button type="button" className="btn btn-primary">{`${t('contact_whatsapp')}`}<i className="fa-brands fa-whatsapp"></i></button></a>
                        </div>
                    </div>
                </>
            }
        </div>
    </>
}
// LOAD REDUX DATA AS PROPS
const mapStateToProps = (state: any) => {
    return {
        brands: state.brands,
        fuelTypes: state.fuelTypes,
        carTypes: state.carTypes,
        locations: state.locations,
        loggedUser: state.loggedUser
    }
}
export const CarContact = connect(mapStateToProps)(CarContactInternal);

