import { t } from 'i18next';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { LINKS } from '../../links';
import { LoggedUser } from '../../redux/LoggedUser';
import { logoutUser } from '../../services/user.service';
import { ChangePassword } from './ChangePassword';

interface UserSectionProps {
    loggedUser: LoggedUser
}

const UserMenuInternal = ({ loggedUser }: UserSectionProps) => {

    const [state, setState] = useState({
        email: '',
        newPassword: '',
        confirmNewPassword: '',
        currentPassword: '',
        loginFail: false,
        showChangePassword: false,
        message: ''
    })

    const logout = () => {
        logoutUser();
    }

    return <>
        <div className="row">
            <div className="col">
            </div>
        </div>
        <div className="row">
            <div className="col-4">
                {`${t('name')}`}:
            </div>
            <div className="col-8">
                {loggedUser?.name && loggedUser.name}
                &nbsp;
                {loggedUser?.last_name && loggedUser.last_name}
            </div>
        </div>
        <div className="row">
            <div className="col-4">
                {`${t('email')}`}:
            </div>
            <div className="col-8">
                {loggedUser?.email}
            </div>
        </div>
        <div className="row">
            <div className="col">
                <ul className="list-unstyled">
                    <li className="nav-item">
                        <a href={LINKS.CAR_SEARCH + "?fav"} className="fw-bold" title={`${t('saved_cars')}`}>
                            {`${t('saved_cars').toUpperCase()} ${loggedUser?.savedCars ? Object.keys(loggedUser.savedCars).length : 0}`}
                        </a>
                    </li>
                </ul>
            </div>
        </div>
        {loggedUser?.is_admin != 0 &&
        <div className="row">
            <div className="col">
                <a href={LINKS.ADD}><button type="button" className="btn">{`${t('add_car')}`} </button> </a>
            </div>
        </div>
        }
        {state.showChangePassword && <ChangePassword />}
        <div className="row">
            <div className="col">
                <button onClick={() => logout()} type="button" className="btn">{`${t('logout')}`}</button>
            </div>
            <div className="col">
                {!loggedUser?.googleTokenId &&
                    <button onClick={() => setState({ ...state, showChangePassword: true })} type="button" className="btn">{`${t('change_password')}`}</button>
                }
            </div>
        </div>

    </>
}
// LOAD REDUX DATA AS PROPS
const mapStateToProps = (state: any) => {
    return {
        loggedUser: state.loggedUser
    }
}
export const UserMenu = connect(mapStateToProps)(UserMenuInternal);
