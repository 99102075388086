import React from 'react';
import { useTranslation } from 'react-i18next';
import { Header, Search } from '../components/content';
import { Footer } from '../components/layouts';

export const CarSearchPage = () => {
    const { t } = useTranslation(['labels']);
    let queryParams: URLSearchParams = new URLSearchParams(window.location.search);

    return <React.Fragment>
        {queryParams.has('fav')
            ?
            <Header bannerText={t('saved_cars')} />
            :
            <Header />
        }
        <Search />
        <Footer />
    </React.Fragment>
}