import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';


export const SelectPrice = ({ handleSelectPrice }) => {
    const { t } = useTranslation(['labels']);
    const [state, setState] = useState({
        priceMin: 0,
        priceMax: 0
    });

    const onChangePriceMin = (priceMin) => {
        setState({ ...state, priceMin});
        handleSelectPrice({ priceMin, priceMax: state.priceMax });
    }

    const onChangePriceMax = (priceMax) => {
        setState({ ...state, priceMax});
        handleSelectPrice({ priceMin: state.priceMin, priceMax });
    }

    const priceOptions = Array.of(50000, 40000, 30000, 20000, 15000, 10000, 5000, 1000);

    return <div class="text-left">
        <h5>{t('price_select')}:</h5>
        <div>
        <div class="input-group-prepend input-group">
            <button class="btn dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> </button>
            <div class="dropdown-menu">
                {priceOptions.map(price =>
                    <a key={price} class="dropdown-item" onClick={() => onChangePriceMin(price)}>{price} E</a>
                )}
            </div>
            <input type="text" class="input dropdown-input form-control" onChange={(e) => onChangePriceMin(e.target.value)} value={state.priceMin > 0 ? state.priceMin : ''} placeholder={t('price_min')} aria-label="Text input with dropdown button" />
        </div>
        <div class="input-group-prepend input-group mt-3">
            <button class="btn dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> </button>
            <div class="dropdown-menu">
                {priceOptions.map(price =>
                    <a key={price} class="dropdown-item" onClick={() => onChangePriceMax(price)}>{price} E</a>
                )}
            </div>
            <input type="text" class="input dropdown-input form-control" onChange={(e) => onChangePriceMax(e.target.value)} value={state.priceMax > 0 ? state.priceMax : ''} placeholder={t('price_max')} aria-label="Text input with dropdown button" />
        </div>
        </div>
    </div>
}