import React from 'react';
import { Fragment } from 'react';
import Suv from '../../img/car-type/suv.png';
import Crossover from '../../img/car-type/crossover.png';
import Break from '../../img/car-type/break.png';
import Microbuz from '../../img/car-type/microbuz.png';
import Sport from '../../img/car-type/sport.png';
import Mini from '../../img/car-type/mini.png';
import Sedan from '../../img/car-type/sedan.png';
import Cabrio from '../../img/car-type/cabrio.png';
import Compact from '../../img/car-type/compact.png';
import Monovolum from '../../img/car-type/monovolum.png';
import Coupe from '../../img/car-type/coupe.png';
import Moto from '../../img/car-type/moto.png';


export const CarTypeGuide = (props) => {

  if (props.type) {
    var icon;
    switch (props.type.id) {
      case 1: icon = Mini; break;
      case 2: icon = Sedan; break;
      case 3: icon = Break; break;
      case 4: icon = Microbuz; break;
      case 5: icon = Sport; break;
      case 6: icon = Suv; break;
      case 7: icon = Moto; break;
      case 8: icon = Compact; break;
      case 9: icon = Crossover; break;
      case 10: icon = Cabrio; break;
      case 11: icon = Coupe; break;
      case 12: icon = Monovolum; break;
    }
    var className = "col-md-3 col-6 my-auto";
    var textClassName = "col-md-3 col-6 my-auto text-left"
    if (props.className) {
      className = props.className;
    }
    if (props.textClassName) {
      textClassName = props.textClassName;
    }
    return (
      <Fragment>
        {icon &&
          <div className={className}>
            <img title={`Tipul de autoturism: ${props.type.name}`} src={icon} className="img-fluid" title={props.type.name.toUpperCase()} alt="Tipul de autoturism" />
          </div>
        }
        {icon && !props.hideText &&
          <div className={textClassName}>
            {props.type.name}
          </div>
        }

      </Fragment>
    )
  }
  return (<Fragment></Fragment>)

}