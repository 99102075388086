import { LOAD_BRANDS, LOAD_CAR_TYPES, LOAD_DEALERS, LOAD_FUEL_TYPES, LOAD_LOCATIONS, LOAD_MODELS, RELOAD_DATA, USER_LOGIN } from "./constants";

const initialState = {
  loggedUser: null,
  brands: [],
  models: [],
  fuelTypes: [],
  dealers: [],
  carTypes: [],
  locations: []
};

function rootReducer(state = initialState, action) {
  if (action.type === USER_LOGIN) {
    console.log('dispatching login');
    return {...state, loggedUser: action.payload};
  }
  if (action.type === LOAD_BRANDS) {
    return {...state, brands: action.payload};
  }
  if (action.type === LOAD_MODELS) {
    return {...state, models: action.payload};
  }
  if (action.type === LOAD_FUEL_TYPES) {
    return {...state, fuelTypes: action.payload};
  }
  if (action.type === LOAD_DEALERS) {
    return {...state, dealers: action.payload};
  }
  if (action.type === LOAD_CAR_TYPES) {
    return {...state, carTypes: action.payload};
  }
  if (action.type === LOAD_LOCATIONS) {
    return {...state, locations: action.payload};
  }
  if (action.type === RELOAD_DATA) {
    return {
      loggedUser: null,
      brands: [],
      models: [],
      fuelTypes: [],
      dealers: [],
      carTypes: [],
      locations: []
    };
  }
  return initialState;
};

export default rootReducer;