import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { gearBoxes } from '../../consts';


export const SelectGearBox = ({ handleSelectGearBox }) => {
    const { t } = useTranslation(['labels']);
    const [state, setState] = useState({
        fuel: []
    });

    const toggleGearbox = (Gearbox) => {
        var fuel = state.fuel;
        if (Gearbox) {
            var fuelIndex = fuel.findIndex(f => f.id === Gearbox.id);
            if (fuelIndex > -1) {
                fuel.splice(fuelIndex, 1);
            } else {
                fuel.push(Gearbox);
            }
        } else {
            // reset
            fuel = [];
        }
        setState({ ...state, fuel });
        handleSelectGearBox(fuel.slice());

    }


    return <div class="text-left w-100">
        <h5>{t('gearbox_select')}:</h5>
        <div class="list-group">
            <a class="list-group-item" onClick={() => toggleGearbox()}>{state.fuel.length === 0 ? <i class="fa-solid fa-circle-check"></i> : ''}{t('fuel_all')}</a>
            {gearBoxes.map(gearbox =>
                <a key={gearbox.id} class="list-group-item" onClick={() => toggleGearbox(gearbox)}>{state.fuel?.includes(gearbox) ? <i class="fa-solid fa-circle-check"></i> : ''}{t(gearbox.label)}</a>
            )}
        </div>
    </div>
}