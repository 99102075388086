import axios from 'axios';
import { Domain } from 'domain';
import { stat } from 'fs';
import React, { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { API, DOMAIN, LINKS } from '../../links';
import { ICar } from '../../models/car';
import { LoggedUser } from '../../redux/LoggedUser';
import { searchForCars } from '../../services/car.service';
import { SelectBrand, SelectCc, SelectFuel, SelectGearBox, SelectPrice, SelectYear } from '../fields';
import { SelectCarType } from '../fields/select-car-type';
import { SelectKm } from '../fields/select-km';
import { Sorting } from '../layouts';
import { SearchResults } from './';

const importAll = (r: any) => {
	let images: any = {};
	r.keys().map((item: any, index: any) => { images[item.replace('./', '')] = r(item); });
	return images;
}

interface SearchProps {
	brands: [],
	fuelTypes: [],
	loggedUser: LoggedUser
}

interface SearchState {
	searchResults: ICar[] | null,
	totalCount: number | null
	selectedBrands: any[],
	selectedCarTypes: any[],
	queryParams: URLSearchParams,
}


const SearchInternal = ({ brands, fuelTypes, loggedUser }: SearchProps) => {
	const { t } = useTranslation(['labels']);
	var history = useHistory();
	const RESULTS_PER_PAGE = 20;

	const [state, setState] = useState<SearchState>({
		searchResults: null,
		totalCount: null,
		selectedBrands: [],
		selectedCarTypes: [],
		queryParams: new URLSearchParams(window.location.search),
	});

	useEffect(() => {
		// Add event listners for the buttons to show/hide the search menu
		const searchButton = document.querySelector('.search-btn');
		const searchMenu = document.querySelector('.search-menu');
		const sortButton = document.querySelector('.sort-btn');
		const sortMenu = document.querySelector('.sort-menu');
		searchButton?.addEventListener('click', () => {
			if (sortMenu?.classList.contains('active')) {
				sortMenu.classList.toggle('active');
			}
			searchMenu?.classList.toggle('active');
		});
		sortButton?.addEventListener('click', () => {
			if (searchMenu?.classList.contains('active')) {
				searchMenu.classList.toggle('active');
			}
			sortMenu?.classList.toggle('active');
		});

		// Add event listners for filter fields
		const refButton = document.querySelector('#ref-btn');
		const refFilter = document.querySelector('.ref-filter');
		const modelButton = document.querySelector('#model-btn');
		const modelFilter = document.querySelector('.model-filter');
		const yearButton = document.querySelector('#year-btn');
		const yearFilter = document.querySelector('.year-filter');
		const kmButton = document.querySelector('#km-btn');
		const kmFilter = document.querySelector('.km-filter');
		const priceButton = document.querySelector('#price-btn');
		const priceFilter = document.querySelector('.price-filter');
		const fuelButton = document.querySelector('#fuel-btn');
		const fuelFilter = document.querySelector('.fuel-filter');
		const ccButton = document.querySelector('#cc-btn');
		const ccFilter = document.querySelector('.cc-filter');
		const gearboxButton = document.querySelector('#gearbox-btn');
		const gearboxFilter = document.querySelector('.gearbox-filter');
		const cartypeButton = document.querySelector('#cartype-btn');
		const cartypeFilter = document.querySelector('.cartype-filter');
		const allFilterButtons = document.querySelectorAll('.filter-btn');
		const allFilterFields = document.querySelectorAll('.filter');

		refButton?.addEventListener('click', () => {
			allFilterButtons.forEach(btn => btn.classList.remove('active'));
			allFilterFields.forEach(btn => btn.classList.remove('active'));
			refButton.classList.toggle('active');
			refFilter?.classList.toggle('active');
		})

		modelButton?.addEventListener('click', () => {
			allFilterButtons.forEach(btn => btn.classList.remove('active'));
			allFilterFields.forEach(btn => btn.classList.remove('active'));
			modelButton.classList.toggle('active');
			modelFilter?.classList.toggle('active');
		})

		priceButton?.addEventListener('click', () => {
			allFilterButtons.forEach(btn => btn.classList.remove('active'));
			allFilterFields.forEach(btn => btn.classList.remove('active'));
			priceButton.classList.toggle('active');
			priceFilter?.classList.toggle('active');
		})

		yearButton?.addEventListener('click', () => {
			allFilterButtons.forEach(btn => btn.classList.remove('active'));
			allFilterFields.forEach(btn => btn.classList.remove('active'));
			yearButton.classList.toggle('active');
			yearFilter?.classList.toggle('active');
		})

		kmButton?.addEventListener('click', () => {
			allFilterButtons.forEach(btn => btn.classList.remove('active'));
			allFilterFields.forEach(btn => btn.classList.remove('active'));
			kmButton.classList.toggle('active');
			kmFilter?.classList.toggle('active');
		})

		fuelButton?.addEventListener('click', () => {
			allFilterButtons.forEach(btn => btn.classList.remove('active'));
			allFilterFields.forEach(btn => btn.classList.remove('active'));
			fuelButton.classList.toggle('active');
			fuelFilter?.classList.toggle('active');
		})

		ccButton?.addEventListener('click', () => {
			allFilterButtons.forEach(btn => btn.classList.remove('active'));
			allFilterFields.forEach(btn => btn.classList.remove('active'));
			ccButton.classList.toggle('active');
			ccFilter?.classList.toggle('active');
		})

		gearboxButton?.addEventListener('click', () => {
			allFilterButtons.forEach(btn => btn.classList.remove('active'));
			allFilterFields.forEach(btn => btn.classList.remove('active'));
			gearboxButton.classList.toggle('active');
			gearboxFilter?.classList.toggle('active');
		})

		cartypeButton?.addEventListener('click', () => {
			allFilterButtons.forEach(btn => btn.classList.remove('active'));
			allFilterFields.forEach(btn => btn.classList.remove('active'));
			cartypeButton.classList.toggle('active');
			cartypeFilter?.classList.toggle('active');
		})
	}, []);

	const search = () => {
		console.log('executing SEARCH on url: ' + API.CAR_SEARCH);
		searchForCars(state.queryParams.toString(), loggedUser)
			.then((searchResults) => {
				debugger
				setState({ ...state, searchResults: searchResults.rows, totalCount: searchResults.totalCount })
				history.push(`${LINKS.CAR_SEARCH}?${state.queryParams.toString()}`);
			});

	}

	const updateQueryParam = (queryParam: string, newValue: string) => {
		let queryParams = state.queryParams;
		if (newValue) {
			queryParams.set(queryParam, newValue);
		} else {
			queryParams.delete(queryParam);
		}
		setState({ ...state, queryParams });
	}

	const handleSelectBrand = (selectedBrands: any) => {
		if (selectedBrands.length > 0) {
			let newQuery = '';
			let modelQuery = '';
			selectedBrands.forEach((brand: any, index: any) => {
				if (brand.models?.length > 0) {
					brand.models.forEach((model: any, modelIndex: any) => {
						modelQuery += ',' + model.id;
					})
					if (modelQuery.startsWith(',')) {
						modelQuery = modelQuery.slice(1);
					}
					updateQueryParam('model', modelQuery);
				} else {
					newQuery += (index == 0 ? '' : ',') + brand.id;
				}
			});
			if (newQuery.startsWith(',')) {
				newQuery = newQuery.slice(1);
			}
			updateQueryParam('brand', newQuery);
		} else {
			updateQueryParam('brand', '');
			updateQueryParam('model', '');
		}
		let diff = selectedBrands.filter((nsb: any) => !state.selectedBrands.map(sb => sb.id).includes(nsb.id))?.[0]?.logo;
		if (!diff) {
			diff = selectedBrands.filter((nsb: any) => state.selectedBrands.map(sb => sb.id).includes(nsb.id))?.[0]?.logo;
		}
		setState({ ...state, selectedBrands });
	}

	const handleSelectYear = ({ yearMin, yearMax }: { yearMin: string, yearMax: string }) => {
		updateQueryParam('yearMin', yearMin);
		updateQueryParam('yearMax', yearMax);
	}

	const handleSelectKm = ({ kmMin, kmMax }: { kmMin: string, kmMax: string }) => {
		updateQueryParam('kmMin', kmMin);
		updateQueryParam('kmMax', kmMax);
	}

	const handleSelectPrice = ({ priceMin, priceMax }: { priceMin: string, priceMax: string }) => {
		updateQueryParam('priceMin', priceMin);
		updateQueryParam('priceMax', priceMax);
	}

	const handleSelectFuel = (fuelTypes: any[]) => {
		if (fuelTypes.length > 0) {
			let newQuery = '';
			fuelTypes.forEach((fuelType, index) => newQuery += (index == 0 ? '' : ',') + fuelType.id);
			updateQueryParam('fuel_type', newQuery);
		} else {
			updateQueryParam('fuel_type', '');
		}
	}

	const handleSelectGearBox = (gearBox: any[]) => {
		if (gearBox.length > 0) {
			let newQuery = ``;
			gearBox.forEach((gb, index) => newQuery += (index == 0 ? '' : ',') + gb.value);
			updateQueryParam('transmission', newQuery);
		} else {
			updateQueryParam('transmission', '');
		}
	}

	const handleSelectCarType = (selectedCarTypes: any[]) => {
		if (selectedCarTypes.length > 0) {
			let newQuery = '';
			selectedCarTypes.forEach((ct, index) => newQuery += (index == 0 ? '' : ',') + ct.id);
			updateQueryParam('car_type', newQuery);
		} else {
			updateQueryParam('car_type', '');
		}
		let diff = selectedCarTypes.filter(nsb => !state.selectedCarTypes.map(sb => sb.id).includes(nsb.id))?.[0]?.icon;
		if (!diff) {
			diff = selectedCarTypes.filter(nsb => state.selectedCarTypes.map(sb => sb.id).includes(nsb.id))?.[0]?.icon;
		}
		setState({ ...state, selectedCarTypes });
	}

	const handleSelectCc = ({ ccMin, ccMax }: { ccMin: string, ccMax: string }) => {
		updateQueryParam('ccMin', ccMin);
		updateQueryParam('ccMax', ccMax);
	}

	const handeSorting = (sort: any) => {
		updateQueryParam('sort', sort.id);
	}

	const pageLink = (page: number): string => {
		let queryParams = new URLSearchParams(window.location.search);
		queryParams.set('pag', page.toString());
		return `${LINKS.CAR_SEARCH}?${queryParams.toString()}`;
	}

	if (state.totalCount == null) {
		debugger
		// initial search
		search();
	}

	debugger
	let pages = Array.from({ length: Math.ceil((state.totalCount ? state.totalCount : 0) / RESULTS_PER_PAGE) }, (_, i) => i + 1);
	let urlCurrentPage = state.queryParams.get('pag');
	let currentPage = urlCurrentPage ? parseInt(urlCurrentPage) : 1;
	return <div className="container-fluid bg-white">
		<div id="search-container" className="wrapper text-center sticky-top bg-white">
			{/* SEARCH AND SORT BUTTON */}
			<button type="button" className="btn sort-btn" title={t('t_sort')} data-bs-toggle="collapse" data-bs-target="#sort-menu" aria-controls="sort-menu" aria-expanded="false" aria-label="Toggle navigation">
				<>
					{t('sort')}
					<i className="fa-solid fa-arrow-down-wide-short" title={t('t_sort')}></i>
				</>
			</button>
			{state.queryParams.has('fav') &&
				<a href={LINKS.CAR_SEARCH} title={`${t('all_cars')}`}><button type="button" className="btn sort-btn" title={t('all_cars')}>
					{`${t('all_cars')}`}
				</button></a>

			}
			<button type="button" className="btn search-btn" title={t('t_search')} data-bs-toggle="collapse" data-bs-target="#search-menu" aria-controls="search-menu" aria-expanded="false" aria-label="Toggle navigation">
				<>
					{t('search')}
					<i className="fa-solid fa-magnifying-glass" title={t('t_search')} ></i>
				</>
			</button>
			<div id="search-menu" data-bs-parent="#search-container" className="search-menu collapse" >
				{/* SEARCH FILTERS */}
				<div className={isMobile?'row mx-auto d-flex': 'row col-7 mx-auto d-flex'}>
						<div className="col-5">
							<ul>
								{loggedUser?.is_admin == 1 &&
									<li><button type="button" className="fw-bold w-100 filter-btn" id="ref-btn" title={t('reference')}><>{t('reference')}</></button></li>
								}
								<li><button type="button" className="fw-bold w-100 filter-btn active" id="model-btn" title={t('model')}><>{t('model')}</></button></li>
								<li><button type="button" className="fw-bold w-100 filter-btn" id="price-btn" title={t('price')}><>{t('price')}</></button></li>
								<li><button type="button" className="fw-bold w-100 filter-btn" id="year-btn" title={t('year')}><>{t('year')}</></button></li>
								<li><button type="button" className="fw-bold w-100 filter-btn" id="km-btn" title={t('km')}><>{t('km')}</></button></li>
								<li><button type="button" className="fw-bold w-100 filter-btn" id="fuel-btn" title={t('fuel')}><>{t('fuel')}</></button></li>
								<li><button type="button" className="fw-bold w-100 filter-btn" id="cc-btn" title={t('cc')}><>{t('cc')}</></button></li>
								<li><button type="button" className="fw-bold w-100 filter-btn" id="gearbox-btn" title={t('gearbox')}><>{t('gearbox')}</></button></li>
								<li><button type="button" className="fw-bold w-100 filter-btn" id="cartype-btn" title={t('car_type')}><>{t('car_type')}</></button></li>
							</ul>
						</div>
						{/* FILTERS VALUES */}
						<div className="col border-left border-primary border-2 mt-2">
							<div className="filter ref-filter justify-content-center">
								<input type="text" className="input" onChange={(e) => updateQueryParam('id', e.target.value)}></input>
							</div>
							<div className="filter model-filter active justify-content-center">
								<SelectBrand handleSelectBrand={(v: any) => handleSelectBrand(v)} />
							</div>
							<div className="filter price-filter">
								<div className="input-group mb-3">
									<SelectPrice handleSelectPrice={(p: any) => handleSelectPrice(p)} />
								</div>
							</div>
							<div className="filter year-filter">
								<SelectYear handleSelectYear={(y: any) => handleSelectYear(y)} />
							</div>
							<div className="filter km-filter">
								<SelectKm handleSelectKm={(y: any) => handleSelectKm(y)} />
							</div>
							<div className="filter fuel-filter">
								<SelectFuel handleSelectFuel={(f: any) => handleSelectFuel(f)} />
							</div>
							<div className="filter cc-filter">
								<SelectCc handleSelectCc={(c: any) => handleSelectCc(c)} />
							</div>
							<div className="filter gearbox-filter">
								<SelectGearBox handleSelectGearBox={(g: any) => handleSelectGearBox(g)} />
							</div>
							<div className="filter cartype-filter">
								<SelectCarType handleSelectCarType={(c: any) => handleSelectCarType(c)} />
							</div>
						</div>
				</div>

				{/* SEARCH BUTTON */}
				<div className="container text-center">
					<button onClick={() => { updateQueryParam('pag', ''); search() }} type="button" className="submit-btn btn fw-bold"
						title={t('t_show_results')} data-bs-toggle="collapse" data-bs-target="#search-menu">
						<u><>{t('show_results')}</></u>
					</button>
				</div>
			</div>

			{/* SORT OPTIONS */}
			<div id="sort-menu" data-bs-parent="#search-container" className="sort-menu collapse">
				<div className="row mx-auto col-12 col-sm-8 col-lg-6">
					<Sorting handleSorting={(s: any) => handeSorting(s)} />
				</div>
				<div className="container text-center">
					<button onClick={() => search()} type="button" className="submit-btn btn fw-bold"
						title={t('t_show_results')} data-bs-toggle="collapse" data-bs-target="#sort-menu">
						<u><>{t('sort')}</></u>
					</button>
				</div>
			</div>

		</div>


		{state.searchResults && state.searchResults?.length > 0
			? <SearchResults searchResults={state.searchResults} />
			: <><div className="col text-center">
				<h2>{state.queryParams.has('fav') ? `${t('no_favorites')}` : `${t('no_results')}`}</h2>
				<button className="btn" onClick={() => history.push(LINKS.PRIVATE_CONSULTING)}>{`${t('request_consulting')}`}</button>
			</div>
			</>
		}

		<div className="row mt-3">
			<div className="col text-center">
				<span>{`${t('page')}`}</span>
			</div>
		</div>
		<div className="row">
			<div className="col text-center display-4">
				{currentPage > 1 &&
					<span><a href={pageLink(currentPage - 1)}><i className="fa-solid fa-angle-left"></i></a></span>
				}
				<b>	{currentPage} </b>
				{currentPage < pages[pages.length - 1] &&
					<span><a href={pageLink(currentPage + 1)}><i className="fa-solid fa-angle-right"></i></a></span>
				}
			</div>
		</div>
		<div className="row">
			<div className="col text-center">
				<span>{pages.map(p => <a href={pageLink(p)}>{p}&nbsp;</a>)}</span>
			</div>
		</div>
	</div>
}
const mapStateToProps = (state: any) => {
	return {
		brands: state.brands,
		fuelTypes: state.fuelTypes,
		carTypes: state.carTypes,
		loggedUser: state.loggedUser
	}
}

export const Search = connect(mapStateToProps)(SearchInternal);
