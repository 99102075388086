import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { API, IMAGES_LINK, LINKS } from '../../links';
import { LoggedUser } from '../../redux/LoggedUser';
import loading from '../../media/loading.gif';
import { loadCarData } from '../../services/car.service';
import { IImage } from '../../models/image';

interface RowProps {
	car: any,
	locations: any,
	loggedUser: LoggedUser,
}

interface RowState {
	isFavorite: boolean,
	email: string,
	loading: boolean,
	images: IImage[]
}

const RowInternal = ({ car, locations, loggedUser }: RowProps) => {
	const { t } = useTranslation(['labels']);
	const [state, setState] = useState<RowState>({
		isFavorite: false,
		email: '',
		loading: false,
		images: []
	});

	useEffect(() => {
		if (loggedUser?.savedCars.includes(car.id)) {
			setState({ ...state, isFavorite: true })
		}
	}, [locations, loggedUser]);

	const delay = (ms: any) => new Promise(res => setTimeout(res, ms));
	const loadDetails = (carId: string) => {
		setState({ ...state, loading: true });
		loadCarData(carId).then(car => {
			setState({ ...state, images: car.images })
		}).catch(error => {
			console.log(error);
			alert('Problema de conexiune');
		})
	}

	const toggleFavorite = () => {
		if (loggedUser) {
			var isFavorite: boolean = !state.isFavorite;
			setState({ ...state, isFavorite });
		}
	}
	var thumbnail = `${IMAGES_LINK}/${car.id}/1.jpg`;
	var location = locations?.filter((l: any) => l.id === car.location)?.[0]?.name;

	const sendEmail = () => {
		axios({
			method: 'post',
			url: API.SHARE,
			data: { email: state.email, link: (LINKS.CAR + car.car_id), car: `${car.brand} ${car.model}` }
		}).then(response => {
			alert(t('sent'))
		});
	}

	if (isMobile) return <React.Fragment>
		<div className="content result-row mobile border mb-3 text-light position-relative">
			{car.sold &&
				<div className="sold"><span><h1>{`${`${t('sold')}`}`}</h1></span></div>
			}
			<div className="row pt-3">
				<div className="col-6">
					<a href={`${LINKS.CAR}/${car.car_id}`}><img className="main-thumb" src={thumbnail} width={100} height={100} /></a>
					<div className="d-flex justify-content-between">
						<span>{car.price.toLocaleString()} Euro</span>
						<span>{car.year}</span>
					</div>
				</div>
				<div className="col-6 font-smaller">
					<div className="row mb-2 border-primary border-2 border-bottom"><b>{`${car.brand} ${car.model}`}</b></div>
					<div className="row">
						<div className="col">{`${t('fuel')}`}: {car.fuelType}</div>
					</div>
					<div className="row">
						<div className="col">{`${t('gearbox')}`}: {car.transmission}</div>
					</div>
					<div className="row">
						<div className="col  d-flex justify-content-around">
							<button type="button" data-bs-toggle="modal" data-bs-target={`#share${car.id}`} className="btn btn-small btn-fa" title={`${t('share_title')}`}>{`${t('share')}`}<i className="fa-solid fa-share-nodes"></i></button>
						</div>
					</div>
					<div className="row">
						<div className="col">
							{location}
						</div>
					</div>
				</div>
			</div>
		</div>
		<div className="modal fade" id={`share${car.id}`} tabIndex={-100} aria-labelledby="exampleModalLabel" aria-hidden="true">
			<div className="modal-dialog">
				<div className="modal-content">
					<div className="modal-header">
						<h5 className="modal-title" id="exampleModalLabel">{`${t('share')}`}</h5>
						<button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
					</div>
					<div className="modal-body">
						<div className="container-flui">
							<div className="row">
								<div className="col">
									{`${t('share_email_address')}`}:
								</div>
							</div>
							<div className="row">
								<div className="col">
									<input type="text" className="input w-100" value={state.email} onChange={(e) => setState({ ...state, email: e.target.value })} />
								</div>
							</div>
							<div className="row">
								<div className="col">
									<button type="button" className="btn" data-bs-dismiss="modal" onClick={() => sendEmail()}>{`${t('send')}`}</button>
								</div>
							</div>
						</div>
					</div>
					<div className="modal-footer">
						<button type="button" className="btn btn-secondary" data-bs-dismiss="modal">{`${t('close')}`}</button>
					</div>
				</div>
			</div>
		</div>
	</React.Fragment>

	return <React.Fragment>
		<div className="content result-row mb-3 text-light">
			{car.sold &&
				<div className="sold rounded"><span><h1>{`${t('sold')}`}</h1></span></div>
			}
			<div className="row w-50 mx-auto my-3">

				<div className="col-auto d-flex align-items-center">
					<a href={`${LINKS.CAR}/${car.car_id}`}><img className="main-thumb" src={thumbnail} width={100} height={100} /></a>
				</div>
				<div className="col">
					<div className="row ">
						<div className="col text-center">
							<b>{`${car.brand} ${car.model}`}</b>
							<i className="fa-solid fa-arrow-down float-right" onClick={() => loadDetails(car.car_id)}></i>
						</div>
					</div>
					<div className="row justify-content-center border-primary border-bottom">
						<div className="col-auto">
							<b>{car.price.toLocaleString()} Euro</b>
						</div>
					</div>
					<div className="row">
						<div className="col-5 pt-3">
							<div className="row text-center ">
								<h6 >{`${t('fuel')}`}</h6>
							</div>
							<div className="row text-center border-primary border-top">
								<h6 >{car.fuelType}</h6>
							</div>
						</div>
						<div className="col-2 pt-3"></div>
						<div className="col-5 pt-3">
							<div className="row text-center ">
								<h6 >{`${t('transmission')}`}</h6>
							</div>
							<div className="row text-center border-primary border-top">
								<h6 >{car.transmission}</h6>
							</div>
						</div>
					</div>
				</div>
				{state.loading &&
					<div className="row">
						<div className="col">
							<img src={loading} />
						</div>
					</div>
				}
				{state.images?.length > 0 &&
					<div className="row">
						<div className="col">
							{state.images?.map(image => <a href={`${API.IMAGE}carId=${car.car_id}&fileName=${image.name}`} data-lightbox="photos"><img className="thumbnail" width={'120'} src={`${API.IMAGE}carId=${car.car_id}&fileName=${image.name}`} /></a>)}
						</div>
					</div>
				}
			</div>
		</div>
		<div className="modal fade" id={`share${car.id}`} tabIndex={-100} aria-labelledby="exampleModalLabel" aria-hidden="true">
			<div className="modal-dialog">
				<div className="modal-content">
					<div className="modal-header">
						<h5 className="modal-title" id="exampleModalLabel">{`${t('share')}`}</h5>
						<button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
					</div>
					<div className="modal-body">
						<div className="container-flui">
							<div className="row">
								<div className="col">
									{`${t('share_email_address')}`}:
								</div>
							</div>
							<div className="row">
								<div className="col">
									<input type="text" className="input w-100" value={state.email} onChange={(e) => setState({ ...state, email: e.target.value })} />
								</div>
							</div>
							<div className="row">
								<div className="col">
									<button type="button" className="btn" data-bs-dismiss="modal" onClick={() => sendEmail()}>{`${t('send')}`}</button>
								</div>
							</div>
						</div>
					</div>
					<div className="modal-footer">
						<button type="button" className="btn btn-secondary" data-bs-dismiss="modal">{`${t('close')}`}</button>
					</div>
				</div>
			</div>
		</div>
	</React.Fragment>
}
// LOAD REDUX DATA AS PROPS
const mapStateToProps = (state: any) => {
	return {
		locations: state.locations,
		loggedUser: state.loggedUser
	}
}
export const Row = connect(mapStateToProps)(RowInternal);