import React from 'react';
import { useTranslation } from 'react-i18next';
import { Consulting, Header } from '../components/content';
import { Footer } from '../components/layouts';

export const PrivateConsultingPage = () => {

    const { t } = useTranslation(['labels']);

    return <React.Fragment>
        <Header bannerText={'consulting'} />
        <Consulting />
        <Footer />
    </React.Fragment>
}