import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';


export const SelectKm = ({ handleSelectKm }) => {
    let queryParams = new URLSearchParams(window.location.search);

    const { t } = useTranslation(['labels']);
    const [state, setState] = useState({
        kmMin: 0,
        kmMax: 0
    });

    useEffect(() => {
        if (queryParams.get('kmMin')) {
            setState({...state, kmMin: queryParams.get('kmMin')})
        }
        if (queryParams.get('kmMax')) {
            setState({...state, kmMax: queryParams.get('kmMax')})
        }
    }, []);

    const onChangeKmMin = (kmMin) => {
        kmMin = parseInt(kmMin);
        setState({ ...state, kmMin});
        handleSelectKm({ kmMin, kmMax: state.kmMax });
    }

    const onChangeKmMax = (kmMax) => {
        kmMax = parseInt(kmMax);
        setState({ ...state, kmMax});
        handleSelectKm({ kmMin: state.kmMin, kmMax });
    }

    var kmOptions = [10000, 50000, 100000, 200000, 30000];
    debugger
    return <div class="text-left">
        <h5>{t('km_select')}:</h5>
        <div>
        <div class="input-group-prepend input-group">
            <button class="btn dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> </button>
            <div class="dropdown-menu">
                {kmOptions.map(km =>
                    <a key={km} class="dropdown-item" onClick={() => onChangeKmMin(km)}>{km}</a>
                )}
            </div>
            <input type="text" class="input dropdown-input form-control" onChange={(e) => onChangeKmMin(e.target.value)} value={state.kmMin > 0 ? state.kmMin : ''} placeholder={t('km_min')} aria-label="Text input with dropdown button" />
        </div>
        <div class="input-group-prepend input-group mt-3">
            <button class="btn dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> </button>
            <div class="dropdown-menu">
                {kmOptions.map(km =>
                    <a key={km} class="dropdown-item" onClick={() => onChangeKmMax(km)}>{km}</a>
                )}
            </div>
            <input type="text" class="input dropdown-input form-control" onChange={(e) => onChangeKmMax(e.target.value)} value={state.kmMax > 0 ? state.kmMax : ''} placeholder={t('km_max')} aria-label="Text input with dropdown button" />
        </div>
        </div>
    </div>
}