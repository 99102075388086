import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import Cookies from 'js-cookie';
import { AUTH, EMAIL, GOOGLE_TOKENID, PWD } from '../consts';
import { API } from '../links';
import { userLogin } from '../redux/actions';
import { LoggedUser } from '../redux/LoggedUser';
import { store } from '../redux/store';


export const googleAuthUser = (tokenId: string) => {
  axios({
    method: 'POST',
    url: API.USER + '?google',
    data: { tokenId }
  }).then((response) => {
    if (response.data) {
      response.data.googleTokenId = tokenId;
      store.dispatch(userLogin(response.data));
    } else {
      Cookies.remove(GOOGLE_TOKENID);
      store.dispatch(userLogin(null));
    }
  }).catch(error => {
  })
}

export const toggleFavoriteCar = async (favStatus: boolean, carId: number, loggedUser: LoggedUser) => {
  try {
    if (!loggedUser) return false;
    let axiosRequest: AxiosRequestConfig = {
      method: 'POST',
      url: API.TOGGLE_FAVORITE,
      data: { favStatus, carId }
    }
    if (loggedUser.googleTokenId) {
      // google user
      let headers = {
        Authorization: `Bearer ${loggedUser.googleTokenId}`
      };
      const resp: AxiosResponse<any> = await axios({
        ...axiosRequest,
        headers
      });
      return resp.status === 200;
    } else {
      // user and password
      let email = localStorage.getItem(EMAIL);
      let password = localStorage.getItem(PWD);
      if (email && password) {
        const resp: AxiosResponse<any> = await axios({
          ...axiosRequest,
          auth: {
            username: email,
            password: password
          }
        })
        return resp.status === 200;
      }
    }
    // user is not logged in
    return false;
  } catch (error) {
    console.log(error);
    return false;
  }
}

export const changeRecoveredPassword = async (password: string, hash: string) => {
  const resp = await axios({
    method: 'POST',
    url: API.CHANGE_RECOVERED_PASSWORD,
    data: { password, hash }
  })
  if (resp.status === 200) {
    return true;
  }
  return false;
}

export const sendPasswordRecoveryEmail = async (email: string) => {
  const resp = await axios({
    method: 'POST',
    url: API.SEND_PASSWORD_RECOVERY_EMAIL,
    data: { email }
  })
  if (resp.status === 200) {
    return true;
  }
  return false;
}

export const changeUserPassword = async (password: string, newPassword: string) => {
  try {
    let email: string | null = localStorage.getItem(EMAIL);
    if (email === null) {
      return false;
    }
    let resp = await axios({
      method: 'POST',
      url: API.USER_PASSWORD,
      auth: {
        username: email,
        password: password
      },
      data: {
        newPassword: newPassword
      }
    });
    return resp;
  } catch (error) {
    return false;
  }
}

export const loginUserPassword = async (email: string, password: string) => {
  try {
    const resp = await axios({
      method: 'POST',
      url: API.USER,
      auth: {
        username: email,
        password: password
      }
    });
    if (resp.status === 200 && resp.data?.id > 0) {
      store.dispatch(userLogin(resp.data));
      localStorage.setItem(EMAIL, email);
      localStorage.setItem(PWD, password);
      return resp.data;
    }
  } catch (error) {
    return false;
  }
}

export const logoutUser = () => {
  Cookies.remove(GOOGLE_TOKENID);
  Cookies.remove(AUTH);
  store.dispatch(userLogin(null));

  localStorage.removeItem(EMAIL);
  localStorage.removeItem(PWD);
}