import React, { Fragment, useState } from 'react';
import { connect } from 'react-redux';
import { useParams, useHistory } from "react-router-dom";
import { CarForm } from '../components/content/CarForm';
import { UserMenu } from '../components/content/UserMenu';
import { reloadData } from '../redux/actions';
import { LoggedUser } from '../redux/LoggedUser';
import { store } from '../redux/store';
import '../css/add-car-page.scss';
import { LINKS } from '../links';
import { useTranslation } from 'react-i18next';

interface AddCarProps {
    loggedUser: LoggedUser
}

const AddCarInternal = ({ loggedUser }: AddCarProps) => {
    let history = useHistory();
    const { t } = useTranslation(['labels']);


    if (!loggedUser) {
        history.push(LINKS.USER_ACCOUNT);
    }

    let carId = new URLSearchParams(window.location.search).get('id');
    // SESSION STATE VARIABLES
    const reloadStore = () => {
        store.dispatch(reloadData());
        window.location.reload();
    }

    return (
        <Fragment>
                  <a href={LINKS.HOME} title={`${t('1motor')}`} className="fw-bold"><i className="fa fa-arrow-left" aria-hidden="true"></i> {`${t('back_to_main')}`}</a>

            {loggedUser ?
                <>
                    <div className="container admin p-3">
                        <h2>Buna, {loggedUser.name}</h2>
                        <CarForm carId={carId} />
                        <button type="button" onClick={() => reloadStore()}>ATENTIE: REINCARCA BAZA DE DATE</button>
                    </div>
                </>
                :
                <UserMenu />
            }
        </Fragment>
    )
}

const mapStateToProps = (state: any) => {
    return {
        loggedUser: state.loggedUser
    }
}

export const AddCarPage = connect(mapStateToProps)(AddCarInternal);