import React, { useEffect, useState } from 'react';
import Modal from "react-bootstrap/Modal";
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

const SelectBrandInternal = ({ allBrands, handleSelectBrand }) => {
    const { t } = useTranslation(['labels']);
    let queryParams = new URLSearchParams(window.location.search);
    const [state, setState] = useState({
        brands: allBrands
    });

    useEffect(() => {
        if (queryParams.get('model')) {
            let selectedModelIds = queryParams.get('model').split(',').map(m => parseInt(m));
            allBrands.forEach(brand => {
                let brandModelsSelected = brand.models.filter(m => selectedModelIds.includes(m.id));
                if (brandModelsSelected?.length > 0) {
                    brand.selected = true;
                    brandModelsSelected.forEach(model => {
                        model.selected = true;
                    })
                    brand.selectedText = brandModelsSelected.map(m=> m.name).toString();
                }
            })
        }
        if (queryParams.get('brand')) {
            let selectedBrands = allBrands.filter(b => queryParams.get('brand').split(',').map(b => parseInt(b)).includes(b.id));
            selectedBrands.forEach(brand => {
                brand.selected = true;
                brand.allModelsChecked = true;
            });
        }
        setState({...state, brands: allBrands})

    }, [allBrands]);

    useEffect(() => {
        
    }, [])

    const pushStateUp = () => {
        var brands = [...state.brands].filter(b => b.selected).map(b => { return { id: b.id, name: b.name, models: b.models, allModelsChecked: b.allModelsChecked, logo: b.logo } });
        brands.map(brand => {
            brand.models = [...brand.models].filter(m => m.selected);
            delete (brand.showSelectModal);
        });
        handleSelectBrand(brands);
    }

    const selectBrand = (brand) => {
        var brands = state.brands;
        var selectedIndex = brands.findIndex(s => s.id === brand.id);
        if (selectedIndex > -1) {
            brands[selectedIndex].selected = !brands[selectedIndex]?.selected;
            if (brands[selectedIndex].selected && brands[selectedIndex].models.filter(m => m.selected).length == 0) {
                brands[selectedIndex].allModelsChecked = true;
            }
        }
        setState({ ...state, brands });
        pushStateUp();
    }

    const selectModel = (brand, model) => {
        var brands = state.brands;
        var selectedBrandIndex = brands.findIndex(b => b.id === brand.id);
        var selectedModelIndex = brands[selectedBrandIndex].models.findIndex(m => m.id === model.id);
        brands[selectedBrandIndex].allModelsChecked = false;
        brands[selectedBrandIndex].models[selectedModelIndex].selected = !brands[selectedBrandIndex].models[selectedModelIndex].selected;
        brands[selectedBrandIndex].selectedText = brands[selectedBrandIndex].models.filter(m => m.selected).map(m => m.name).join(", ");
        setState({ ...state, brands });
        pushStateUp();
    }

    const resetModelSelection = (brand) => {
        var brands = state.brands;
        var selectedBrandIndex = brands.findIndex(b => b.id === brand.id);
        brands[selectedBrandIndex].allModelsChecked = true;
        brands[selectedBrandIndex].models.map(m => m.selected = false);
        delete (brands[selectedBrandIndex].selectedText);
        setState({ ...state, brands });
        pushStateUp();
    }

    const openModal = (brand) => {
        var brands = state.brands;
        var brandIndex = brands.findIndex(s => s.id === brand.id);
        if (brandIndex > -1) {
            brands[brandIndex].showSelectModal = true;
            setState({ ...state, brands });
        }
    }

    const closeModal = (brand) => {
        var brands = state.brands;
        var brandIndex = brands.findIndex(s => s.id === brand.id);
        if (brandIndex > -1) {
            brands[brandIndex].showSelectModal = false;
            setState({ ...state, brands });
        }
    }
    
    return <React.Fragment>
        <div class="list-group w-100">
            {state.brands?.map(brand =>
                <div key={brand.id} class="bg-light">
                    <a onClick={() => selectBrand(brand)} class="list-group-item">
                        {brand.selected && <i class="fa-solid fa-circle-check"></i>}
                        {brand.name}
                        {(brand.selected && brand.selectedText) && <p>{brand.selectedText}</p>}
                    </a>
                    {brand.selected &&
                        <React.Fragment>
                            <button type="button" onClick={() => openModal(brand)} class="btn btn-small">{`${t('model_select')} ${brand.name}`}</button>
                            <Modal show={brand.showSelectModal} onHide={() => closeModal(brand)}>
                                <Modal.Header closeButton>
                                    <div class="list-group-item">
                                        {brand.allModelsChecked ? (<a><i class="fa-solid fa-circle-check"></i>{t('all_models')}</a>)
                                            : (<a onClick={() => resetModelSelection(brand)}>{t('reset_selection')}</a>)
                                        }
                                    </div>
                                </Modal.Header>
                                <Modal.Body>
                                    {brand.models?.map(model =>
                                        <a key={model.id} class="list-group-item" onClick={() => selectModel(brand, model)}>
                                            {model.selected && <i class="fa-solid fa-circle-check"></i>}
                                            {model.name}
                                        </a>
                                    )}
                                </Modal.Body>
                                <Modal.Footer>
                                    <button type="button" class="btn" onClick={() => closeModal(brand)}>{t('close')}</button>
                                </Modal.Footer>
                            </Modal>
                        </React.Fragment>
                    }
                </div>
            )}
        </div>
    </React.Fragment>
}
const mapStateToProps = (state) => {
    return {
        allBrands: state.brands.map(brand => ({ ...brand, showSelectModal: false })),
    }
}

export const SelectBrand = connect(mapStateToProps)(SelectBrandInternal);