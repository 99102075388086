import axios from 'axios';
import React, { useRef, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { API, LINKS } from '../../links';

interface ConsultingState {
    brand: string,
    engine: string,
    fuel: string,
    gearbox: string,
    interior: string,
    color: string,
    details: string,
    contact: string,
    insurance: boolean,
    casco: boolean,
    leasing: boolean
}


const ConsultingInternal = () => {
    const { t } = useTranslation(['labels']);

    const [state, setState] = useState<ConsultingState>({
        brand: '',
        engine: '',
        fuel: '',
        gearbox: '',
        interior: '',
        color: '',
        details: '',
        contact: '',
        insurance: false,
        casco: false,
        leasing: false
    })

    
    let colClass = "col-6 mx-auto";
    if (isMobile) {
        colClass = "col-11 mx-auto";
    }

    const requestPrivateConsulting = () => {
        axios({
            url: API.CONSULTING,
            method: 'post',
            data: state
        }).then(response => {
            alert(t('sent'));
        }).catch(error => {
            alert(t('something_failed'));
        })
    }

    return <React.Fragment>
        <div className="wrapper">
            <div className="content mt-3">
                <div className="row text-center my-3">
                    <div className="col">
                        {`${t('private_consulting_form')}`}
                    </div>
                </div>
                <hr />
                <div className="row my-3">
                    <div className={colClass}>
                        <span>{`${t('consulting_brand')}`}:</span>
                        <input onChange={(e) => setState({ ...state, [e.target.id]: e.target.value })} id="brand" value={state.brand} type="text" className="input w-100" maxLength={200} />
                    </div>
                </div>
                <div className="row my-3">
                    <div className={colClass}>
                        <span>{`${t('consulting_engine')}`}:</span>
                        <input onChange={(e) => setState({ ...state, [e.target.id]: e.target.value })} id="engine" value={state.engine} type="text" className="input w-100" maxLength={200} />
                    </div>
                </div>
                <div className="row my-3">
                    <div className={colClass}>
                        <span>{`${t('consulting_fuel')}`}:</span>
                        <input onChange={(e) => setState({ ...state, [e.target.id]: e.target.value })} id="fuel" value={state.fuel} type="text" className="input w-100" maxLength={200} />
                    </div>
                </div>
                <div className="row my-3">
                    <div className={colClass}>
                        <span>{`${t('consulting_gearbox')}`}:</span>
                        <input onChange={(e) => setState({ ...state, [e.target.id]: e.target.value })} type="text" id="gearbox" value={state.gearbox} className="input w-100" maxLength={200} />
                    </div>
                </div>
                <div className="row my-3">
                    <div className={colClass}>
                        <span>{`${t('consulting_interior')}`}:</span>
                        <input onChange={(e) => setState({ ...state, [e.target.id]: e.target.value })} type="text" id="interior" value={state.interior} className="input w-100" maxLength={200} />
                    </div>
                </div>
                <div className="row my-3">
                    <div className={colClass}>
                        <span>{`${t('consulting_color')}`}:</span>
                        <input onChange={(e) => setState({ ...state, [e.target.id]: e.target.value })} type="text" id="color" value={state.color} className="input w-100" maxLength={200} />
                    </div>
                </div>
                <div className="row my-3">
                    <div className={colClass}>
                        <span>{`${t('consulting_other_details')}`}:</span>
                        <textarea onChange={(e) => setState({ ...state, [e.target.id]: e.target.value })} className="input w-100" id="details" value={state.details} rows={5} maxLength={500} />
                    </div>
                </div>
                <div className="row my-3">
                    <div className="col-auto mx-auto">
                        <div className="row">
                            <div className={colClass}>
                                <div className="form-check form-switch">
                                    <input onChange={(e) => setState({ ...state, [e.target.id]: e.target.value })} title={`${t('insurance')}`} id="insurance" checked={state.insurance} className="form-check-input" type="checkbox" />
                                    <label className="form-check-label">{`${t('insurance')}`}</label>
                                </div>
                            </div>
                            <div className={colClass}>
                                <div className="form-check form-switch">
                                    <input onChange={(e) => setState({ ...state, [e.target.id]: e.target.value })} title={`${t('insurance_casco')}`} id="casco" checked={state.casco} className="form-check-input" type="checkbox"/>
                                    <label className="form-check-label">{`${t('insurance_casco')}`}</label>
                                </div>
                            </div>
                            <div className={colClass}>
                                <div className="form-check form-switch">
                                    <input onChange={(e) => setState({ ...state, [e.target.id]: e.target.value })} title={`${t('leasing')}`} id="leasing" checked={state.leasing} className="form-check-input" type="checkbox"/>
                                    <label className="form-check-label">{`${t('leasing')}`}</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <hr />
                <div className="row my-3">
                    <div className={colClass}>
                        <span>{`${t('contact_details')} (${t('field_mandatory')})`}:</span>
                        <input onChange={(e) => setState({ ...state, [e.target.id]: e.target.value })} id="contact" value={state.contact} type="text" className="input w-100" maxLength={200} />
                    </div>
                </div>
                <div className="row my-3">
                    <div className={colClass}>
                        <button onClick={requestPrivateConsulting} type="button" className="btn btn-primary">{`${t('request_consulting')}`}</button>
                    </div>
                </div>
            </div>
        </div>
    </React.Fragment >
}
// LOAD REDUX DATA AS PROPS
const mapStateToProps = (state: any) => {
    return {
        loggedUser: state.loggedUser
    }
}
export const Consulting = connect(mapStateToProps)(ConsultingInternal);


