import axios, { AxiosResponse } from "axios";
import { AnyAaaaRecord } from "dns";
import { EMAIL, PWD } from "../consts";
import { API } from "../links";
import { ICar } from "../models/car";
import { LoggedUser } from "../redux/LoggedUser";


export const loadCarData = async (carId: string): Promise<ICar> => {
	let resp: AxiosResponse<ICar> = await axios({
		method: 'post',
		url: `${API.CAR_SEARCH}?car_id=${carId}`
	});
	return resp.data;
}

export interface SearchResults {
	totalCount: number,
	rows: Array<ICar>
}

export const searchForCars = async (query: string, loggedUser: LoggedUser): Promise<SearchResults> => {
	let resp: AxiosResponse<SearchResults>;
	let headers;
	if (loggedUser?.googleTokenId) {
		headers = {
			Authorization: `Bearer ${loggedUser.googleTokenId}`
		};
	}
	if (headers) {
		resp = await axios({
			method: 'get',
			url: `${API.CAR_SEARCH}?${query}`,
			headers
		})
		return resp.data;
	}
	let email = localStorage.getItem(EMAIL);
	let password = localStorage.getItem(PWD);
	if (email && password) {
		resp = await axios({
			method: 'get',
			url: `${API.CAR_SEARCH}?${query}`,
			auth: {
				username: email,
				password: password
			}
		})
		return resp.data;
	}
	resp = await axios({
		method: 'get',
		url: `${API.CAR_SEARCH}?${query}`,
	});
	return resp.data;
}


export const saveFavorite = async (query: string, loggedUser: LoggedUser): Promise<Array<ICar>> => {

	let resp: AxiosResponse<Array<ICar>> = await axios({
		method: 'POST',
		url: API.SAVE_FAVORITE
	})
	return resp.data;
}

export const saveOrUpdateCar = async (data: FormData, loggedUser: LoggedUser) => {
	debugger
	let resp: AxiosResponse<any>;
	let headers;
	if (loggedUser?.googleTokenId) {
		headers = {
			Authorization: `Bearer ${loggedUser.googleTokenId}`
		};
	}
	if (headers) {
		resp = await axios({
			method: 'POST',
			url: API.SAVE_CAR,
			headers,
			data
		})
		if (resp.status === 200) {
			return resp.data;
		}
	} else {
		let email = localStorage.getItem(EMAIL);
		let password = localStorage.getItem(PWD);
		if (email && password) {
			resp = await axios({
				method: 'POST',
				url: API.SAVE_CAR,
				auth: {
					username: email,
					password: password
				},
				data
			})
			if (resp.status === 200) {
				return resp.data;
			}
		}
	}
	return false;
}

export const deleteCar = async (data: FormData, loggedUser: LoggedUser) => {
	debugger
	let resp: AxiosResponse<any>;
	let headers;
	if (loggedUser?.googleTokenId) {
		headers = {
			Authorization: `Bearer ${loggedUser.googleTokenId}`
		};
	}
	if (headers) {
		resp = await axios({
			method: 'POST',
			url: API.DELETE_CAR,
			headers,
			data
		})
		if (resp.status === 200) {
			return resp.data;
		}
	} else {
		let email = localStorage.getItem(EMAIL);
		let password = localStorage.getItem(PWD);
		if (email && password) {
			resp = await axios({
				method: 'POST',
				url: API.DELETE_CAR,
				auth: {
					username: email,
					password: password
				},
				data
			})
			if (resp.status === 200) {
				return resp.data;
			}
		}
	}
	return false;
}
