
import Cookies from "js-cookie";
import { AUTH, EMAIL, GOOGLE_TOKENID, PWD } from "../consts.js";
import { googleAuthUser, loginUserPassword } from "../services/user.service.ts";
import { addBrands, addCarTypes, addDealers, addFuelTypes, addLocations } from "./actions";
import { store } from './store';

const brandsEndpoint = 'https://1motor.ro/endpoints/get-brands-and-models.php';
const fuelTypesEndpoint = 'https://1motor.ro/endpoints/get-fuel-types.php';
const carTypesEndpoint = 'https://1motor.ro/endpoints/get-car-types.php';
const locationsEndpoint = 'https://1motor.ro/endpoints/get-locations.php';
const dealersEndpoint = 'https://1motor.ro/endpoints/get-dealers.php';


export const loadBrands = async () => {
  if (!store || !store.getState() || !store.getState().brands || store.getState().brands == 0) {
    console.log('API CALL: car brands')
    fetch(brandsEndpoint)
      .then(res => res.json())
      .then((data) => {
        data.map(element => {
          element.models = JSON.parse(element.models);
        })
        store.dispatch(addBrands(data));
      })
      .catch(err => {
        alert('EROARE DE CONEXIUNE' + err);
      })
  }
}

export const loadLocations = async () => {
  console.log(store.getState());
  if (!store || !store.getState() || !store.getState().locations || store.getState().locations.length == 0) {
    console.log('API CALL: locations')
    fetch(locationsEndpoint)
      .then(res => res.json())
      .then((data) => {
        var array = [];
        data.map(element => {
          array.push(element)
        })
        store.dispatch(addLocations(array));
      })
      .catch(err => {
        alert('EROARE DE CONEXIUNE' + err);
      })
  }
}

export const loadFuelTypes = () => {
  if (!store || !store.getState() || !store.getState().fuelTypes || store.getState().fuelTypes == 0) {
    console.log('API CALL: fuel types')
    fetch(fuelTypesEndpoint)
      .then(res => res.json())
      .then((data) => {
        var array = [];
        data.map(element => {
          array.push(element)
        })
        store.dispatch(addFuelTypes(array));
      })
      .catch(err => {
        alert('EROARE DE CONEXIUNE' + err);
      })
  }
}

export const loadDealers = () => {
  if (!store || !store.getState() || !store.getState().dealers || store.getState().dealers == 0) {
    console.log('API CALL: delears')
    fetch(dealersEndpoint)
      .then(res => res.json())
      .then((data) => {
        var array = [];
        data.map(element => {
          array.push(element)
        })
        store.dispatch(addDealers(array));
      })
      .catch(err => {
        alert('EROARE DE CONEXIUNE' + err);
      })
  }
}


export const loadCarTypes = () => {
  if (!store || !store.getState() || !store.getState().carTypes || store.getState().carTypes == 0) {
    console.log('API CALL: car types')
    fetch(carTypesEndpoint)
      .then(res => res.json())
      .then((data) => {
        var array = [];
        data.map(element => {
          array.push(element)
        })
        store.dispatch(addCarTypes(array));
      })
      .catch(err => {
        alert('EROARE DE CONEXIUNE' + err);
      })
  }
}

export const loadUser = async () => {
  if (store.getState().loggedUser) {
    return;
  }
  if (Cookies.get(GOOGLE_TOKENID)) {
    googleAuthUser(Cookies.get(GOOGLE_TOKENID));
  } else if (localStorage.getItem(EMAIL) && localStorage.getItem(PWD)) {
    const login = await loginUserPassword(localStorage.getItem(EMAIL), localStorage.getItem(PWD));
    if (!login) {
      // for failed logins remove EMAIL and PWD
      localStorage.removeItem(EMAIL);
      localStorage.removeItem(PWD);
    }
  }
}