export const API_BASE = 'https://1motor.ro/endpoints';
export const DOMAIN = window.location.href.includes('localhost') ? 'http://localhost:3000' : 'https://1motor.ro';

// API LINKS
export const API = {
    SAVE_FAVORITE: API_BASE + '/save-favorite.php',
    CONSULTING: API_BASE + '/consulting.php',
    CAR_SEARCH: API_BASE + '/car-search.php',
    CAR_IMAGES: API_BASE + '/car-images.php?car_id=',
    IMAGE: API_BASE + '/image-v2.php?',
    USER: API_BASE + '/user.php',
    USER_PASSWORD: API_BASE + '/change-user-password.php',
    TOGGLE_FAVORITE: API_BASE + '/toggle-favorite.php?',
    SHARE: API_BASE + '/share.php',
    NEW_ACCOUNT: API_BASE + '/new-user.php',
    SAVED_CARS: API_BASE + '/car-search.php?anunturi-salvate',
    SEND_PASSWORD_RECOVERY_EMAIL: API_BASE + '/send-password-recovery-email.php',
    CHANGE_RECOVERED_PASSWORD: API_BASE + '/change-recovered-user-password.php',
    SAVE_CAR: API_BASE + '/save-car.php',
    DELETE_CAR: API_BASE + '/delete-car.php'
}

// RELATIVE LINKS
export const LINKS = {
    HOME: '/',
    CAR_SEARCH: '/cauta-autoturism/',
    CAR: '/autoturism',
    NEW_ACCOUNT: '/cont-nou',
    ACCOUNT_ACTIVATED: '/cont-activat',
    USER_ACCOUNT: '/contul-meu',
    PRIVATE_CONSULTING: '/consultanta-personalizata',
    RECOVER_PASSWORD: '/recuperare-parola',
    ADD: '/adaugare'
}

// ABSOLUTE LINKS
export const IMAGES_LINK = 'https://1motor.ro/images/';
export const FACEBOOK_LINK = 'https://www.facebook.com/1motor.ro';
export const INSTAGRAM_LINK = 'https://www.instagram.com/1motor.ro/';