import { applyMiddleware, createStore } from 'redux'
import logger from 'redux-logger'
import { persistReducer, persistStore } from 'redux-persist' // imports from redux-persist
import storage from 'redux-persist/lib/storage/session' // defaults to localStorage for web
import rootReducer from './reducers' // Root reducer


const persistConfig = { // configuration object for redux-persist
  key: 'root',
  storage, // define which storage to use
}

const persistedReducer = persistReducer(persistConfig, rootReducer) // create a persisted reducer

const store = createStore(
  persistedReducer,
  applyMiddleware(logger)
)

const persistor = persistStore(store); // used to create the persisted store, persistor will be used in the next step

export { store, persistor }

