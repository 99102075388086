import React from 'react';
import HomePageVideo from '../media/home-page.mp4';
import '../css/home-page.scss';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { LINKS } from '../links';
import HeaderLogo from '../img/header-logo.png';


export const HomePage = () => {

    const { t } = useTranslation(['labels']);

    return <React.Fragment>
        <div className="home-page-wrapper">
            <video autoPlay muted loop className="home-page-video">
                <source src={HomePageVideo} type="video/mp4" />
            </video>
            <div className="home-page-menu">
                <img title={t('1motor')} src={HeaderLogo} width={'250'} className="mb-3" />
                <Link to={LINKS.CAR_SEARCH}>
                    <button className="btn btn-primary" title={`${t('cars')}`} ><b>{`${t('cars')}`}</b></button>
                </Link>
                <Link to={LINKS.PRIVATE_CONSULTING}>
                    <button className="btn btn-primary" title={`${t('consulting')}`} ><b>{`${t('consulting')}`}</b></button>
                </Link>
                <Link to={LINKS.USER_ACCOUNT}>
                    <button className="btn btn-primary" title={`${t('account')}`} ><b>{`${t('account')}`}</b></button>
                </Link>
            </div>
        </div>
    </React.Fragment>
}