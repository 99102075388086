import React from 'react';
import { useTranslation } from 'react-i18next';
import { RecoverPassword } from '../components/content/RecoverPassword';
import { LINKS } from '../links';

export const RecoverPasswordPage = () => {
    const { t } = useTranslation(['labels']);

    return  <>
            <a href={LINKS.HOME} title={`${t('1motor')}`} className="fw-bold"><i className="fa fa-arrow-left" aria-hidden="true"></i> {`${t('back_to_main')}`}</a>
        <br />
    <RecoverPassword />
    </>
}