import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import './css/index.scss';
import './i18next';
import { LINKS } from './links';
import { HomePage, NewAccountPage, AccountActivatedPage, CarSearchPage, UserAccountPage, RecoverPasswordPage, PrivateConsultingPage, AddCarPage } from './pages';
import { loadBrands, loadCarTypes, loadDealers, loadFuelTypes, loadLocations, loadUser, persistor, store } from './redux';

// load basic information
loadBrands();
loadCarTypes();
loadDealers();
loadFuelTypes();
loadLocations();
loadUser();

const routing = (
	<Suspense fallback={<div>LOADING</div>}>
		<Router>
			<Switch>
				<Provider store={store}>
					<PersistGate loading={null} persistor={persistor}>
						<Route exact path={LINKS.HOME} component={() => <HomePage />} />
						<Route path={LINKS.NEW_ACCOUNT} component={() => <NewAccountPage />} />
						<Route path={LINKS.ACCOUNT_ACTIVATED} component={() => <AccountActivatedPage />} />
						<Route path={LINKS.CAR_SEARCH} component={() => <CarSearchPage />} />
						<Route path={LINKS.USER_ACCOUNT} component={() => <UserAccountPage />} />
						<Route path={LINKS.PRIVATE_CONSULTING} component={() => <PrivateConsultingPage />} />
						<Route path={LINKS.RECOVER_PASSWORD} component={() => <RecoverPasswordPage />} />
						<Route path={LINKS.ADD} component={() => <AddCarPage />} />
					</PersistGate>
				</Provider>
			</Switch>
		</Router>
	</Suspense>
);

ReactDOM.render(routing, document.getElementById("root"));
