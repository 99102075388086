import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { DOMAIN } from '../links';

export const AccountActivatedPage = () => {

    const { t } = useTranslation(['labels']);

    return <React.Fragment>
        <div className="modal-dialog">
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">{`${t('account_activated')}`}</h5>
                </div>
                <div className="modal-body">
                    <a href={DOMAIN}>
                        <button className="btn" type="button">{`${t('back_to_homepage')}`}</button>
                    </a>
                </div>
            </div>
        </div>
    </React.Fragment>
}