import { stat } from 'fs';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { PassThrough } from 'stream';
import { LoggedUser } from '../../redux/LoggedUser';
import { changeUserPassword } from '../../services/user.service';


interface ChangePasswordProps {
    loggedUser: LoggedUser
}

interface ChangePasswordState {
    password: string,
    passwordError: boolean,
    newPassword: string,
    confirmNewPassword: string,
    newPasswordError: boolean,
    passwordChanged: boolean
}

const ChangePasswordInternal = ({ loggedUser }: ChangePasswordProps) => {
    const { t } = useTranslation(['labels']);

    const [state, setState] = useState<ChangePasswordState>({
        password: '',
        passwordError: false,
        newPassword: '',
        confirmNewPassword: '',
        newPasswordError: false,
        passwordChanged: false
    });

    const changePassword = () => {
        if (!state.password) {
            setState({ ...state, passwordError: true });
            return;
        }
        if (!state.newPassword || state.newPassword !== state.confirmNewPassword) {
            setState({ ...state, passwordError: false, newPasswordError: true });
            return;
        }
        changeUserPassword(state.password, state.newPassword).then(resp => {
            if (resp && resp.status === 200) {
                setState({ ...state, passwordError: false, newPasswordError: false, passwordChanged: true })
            } else {
                alert(t('login_fail'));
            }
        }).catch(error => {
            alert(t('something_failed'))
        })
    }

    return <>
        {state.passwordChanged ?
            <>
                <div className="row">
                    <div className="col d-flex align-items-center fw-bold text-danger">
                        {`${t('password_changed')}`}
                    </div>
                </div>
            </>
            :
            <>
                <div className="row">

                    <div className="col d-flex align-items-center">
                        {`${t('password')}`}:
                    </div>
                    <div className="col">
                        <input onChange={(e) => setState({ ...state, [e.target.id]: e.target.value })} id="password" type="password" className="input" value={state.password} />
                    </div>
                </div>
                {state.passwordError &&
                    <div className="row">
                        <div className="col text-danger fw-bold">
                            {`${t('add_current_password')}`}
                        </div>
                    </div>
                }
                <div className="row">
                    <div className="col d-flex align-items-center">
                        {`${t('new_password')}`}:
                    </div>
                    <div className="col">
                        <input onChange={(e) => setState({ ...state, [e.target.id]: e.target.value })} id="newPassword" type="password" className="input" value={state.newPassword} />
                    </div>
                </div>
                {state.newPasswordError &&
                    <div className="row">
                        <div className="col text-danger fw-bold">
                            {`${t('new_passwords_missmatch')}`}
                        </div>
                    </div>
                }
                <div className="row">
                    <div className="col d-flex align-items-center">
                        {`${t('confirm_password')}`}:
                    </div>
                    <div className="col">
                        <input onChange={(e) => setState({ ...state, [e.target.id]: e.target.value })} id="confirmNewPassword" type="password" className="input" value={state.confirmNewPassword} />
                    </div>
                </div>
                <div className="row">
                    <div className="offset-6 col-6">
                        <button onClick={changePassword} type="button" className="btn">{`${t('apply_change')}`}</button>
                    </div>
                </div>
            </>
        }
    </>

}
// LOAD REDUX DATA AS PROPS
const mapStateToProps = (state: any) => {
    return {
        loggedUser: state.loggedUser
    }
}
export const ChangePassword = connect(mapStateToProps)(ChangePasswordInternal);
