import axios from 'axios';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { API, DOMAIN } from '../../links';

const PASSWORD_MISSMATCH_ERROR = 'passwords_missmatch';
const FIELDS_MANDATORY_ERROR = 'fields_mandatory_error';


export const NewAccount = () => {

    const { t } = useTranslation(['labels']);
    const [state, setState] = useState({
        email: '',
        password: '',
        name: '',
        passwordConfirmation: '',
        errorMessage: '',
    })

    const createAccount = () => {
        if (!state.email || !state.password || !state.passwordConfirmation) {
            setState({ ...state, errorMessage: FIELDS_MANDATORY_ERROR });
            return;
        }
        if (state.password && state.password != state.passwordConfirmation) {
            setState({ ...state, errorMessage: PASSWORD_MISSMATCH_ERROR });
            return;
        }
        axios({
            method: 'post',
            url: API.NEW_ACCOUNT,
            data: { email: state.email, password: state.password, name: state.name }
        }).then((response) => {
            alert(t('new_account_email_sent'));
            window.location.replace(DOMAIN);
        }).catch(error => {
            alert("A aparut o eroare. Vă rugam incercați mai târziu.")
        })
        
    }

    return <React.Fragment>
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">{t('new_account')}</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <div class="row">
                            <div class="col-4 d-flex align-items-center">{t('email')}*:</div>
                            <div class="col-8"><input id="email" type="text" class="input w-100" onChange={(e) => setState({ ...state, [e.target.id]: e.target.value })} /></div>
                        </div>
                        <hr />
                        <div class="row">
                            <div class="col">
                                <b>{t('activation_on_this_email')}</b>
                            </div>
                        </div>
                        <hr />
                        <div class="row">
                            <div class="col-4 d-flex align-items-center">{t('Name')}:</div>
                            <div class="col-8"><input id="name" type="text" class="input w-100 invalid" value={state.name} onChange={(e) => setState({ ...state, [e.target.id]: e.target.value })} /></div>
                        </div>
                        <div class="row">
                            <div class="col-4 d-flex align-items-center">{t('password')}*:</div>
                            <div class="col-8"><input id="password" type="password" class="input w-100" value={state.password} onChange={(e) => setState({ ...state, [e.target.id]: e.target.value })} /></div>
                        </div>
                        <div class="row">
                            <div class="col-4 d-flex align-items-center">{t('confirm_password')}*:</div>
                            <div class="col-8"><input id="passwordConfirmation" type="password" class="input w-100 invalid" value={state.passwordConfirmation} onChange={(e) => setState({ ...state, [e.target.id]: e.target.value })} /></div>
                        </div>
                        <hr />
                        <div class="row">
                            <div class="col">
                                <b>{t('account_inactive_until_confirmation')}</b>
                            </div>
                        </div>
                        <hr />
                        <div class="row">
                            <div class="col" class="text-center">
                                <button type="button" class="btn" data-bs-dismiss="modal" onClick={() => createAccount()}>{t('create_account')}</button>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col text-danger">
                                <b>{t(state.errorMessage)}</b>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" onClick={() => setState({ ...state, showModal: !state.showModal })}>{t('close')}</button>
                    </div>
                </div>
            </div>
    </React.Fragment>
}