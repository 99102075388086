import { gapi } from 'gapi-script';
import Cookies from 'js-cookie';
import React, { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import GoogleLogin from 'react-google-login';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { GOOGLE_TOKENID as GOOGLE_TOKEN_ID } from '../../consts';
import { LINKS } from '../../links';
import { LoggedUser } from '../../redux/LoggedUser';
import { googleAuthUser, loginUserPassword, logoutUser } from '../../services/user.service';

const googleClientId = "585179420910-t31ddfi9pc4ipcbj2o6ouajvlpscm9v2.apps.googleusercontent.com";

interface UserLoginProps {
    loggedUser: LoggedUser
}

const UserLoginInternal = ({loggedUser}: UserLoginProps) => {
    const { t } = useTranslation(['labels']);
    let history = useHistory();

    const [state, setState] = useState({
        email: '',
        password: '',
        loginFail: false
    })

    useEffect(() => {
        function start() {
            gapi.client.init({ clientId: googleClientId, scope: "" })
        }
        gapi.load('client:auth2', start);
    }, [loggedUser]);

    const googleLoginSuccess = (response: any) => {
        googleAuthUser(response.tokenId);
        Cookies.set(GOOGLE_TOKEN_ID, response.tokenId);
    }

    const googleLoginFail = (response: any) => {
        console.log(response);
    }

    const logout = () => {
        logoutUser();
    }

    const login = async () => {
        if (!state.email || !state.password) {
            setState({ ...state, loginFail: true })
            return;
        }
        let login = await loginUserPassword(state.email, state.password);
        if (!login) {
            setState({ ...state, loginFail: true })
        }
    }

    return <>
        <div className="row">
            <div className="col-auto">
                <GoogleLogin
                    clientId={googleClientId}
                    buttonText={t('google_login')}
                    onSuccess={googleLoginSuccess}
                    onFailure={googleLoginFail}
                    cookiePolicy={'single_host_origin'}
                />
            </div>
            {isMobile
                ? <div className="row pt-3 pb-3"><div className="col-auto">{`${t('or')}`} </div></div>
                : <div className="col-auto">{`${t('or')}`} </div>
            }
            <div className="col">
                <div className="row">
                    <div className="col">
                        {`${t('email')}`}
                    </div>
                    <div className="col">
                        <input onChange={(e) => setState({ ...state, [e.target.id]: e.target.value })} id="email" type="text" className="input" value={state.email} />
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        {`${t('password')}`}
                    </div>
                    <div className="col">
                        <input onChange={(e) => setState({ ...state, [e.target.id]: e.target.value })} id="password" type="password" className="input" value={state.password} />
                    </div>
                </div>
                {state.loginFail &&
                    <div className="row">
                        <div className="col-auto text-danger">
                            <b>{`${t('login_fail')}`}</b>
                        </div>
                    </div>
                }
                <div className="row">
                    <div className="col-6 d-flex align-items-center">
                        <a href={LINKS.RECOVER_PASSWORD}>{`${t('recover_password')}`}</a>
                    </div>
                    <div className="col-6">
                        <button onClick={() => login()} type="button" className="btn">{`${t('login')}`}</button>
                    </div>
                </div>
                <hr />
                <div className="row">
                    <div className="col-auto d-flex align-items-center">
                        {`${t('or_create_account')}`}
                    </div>
                    <div className="col-auto">
                        <Link to={LINKS.NEW_ACCOUNT}>
                            <button data-bs-toggle="modal" data-bs-target="#new-user" className="btn" type="button" >{`${t('new_account')}`}</button>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    </>
}
// LOAD REDUX DATA AS PROPS
const mapStateToProps = (state: any) => {
    return {
        loggedUser: state.loggedUser
    }
}
export const UserLogin = connect(mapStateToProps)(UserLoginInternal);
