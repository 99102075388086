import React from 'react';
import { useTranslation } from 'react-i18next';
import { FACEBOOK_LINK, INSTAGRAM_LINK } from '../../links';

export const Footer = () => {
  const { t } = useTranslation(['labels']);

  return <React.Fragment>
    <footer id="sticky-footer" class="flex-shrink-0 mt-3 py-4 bg-dark text-white-50">
      <div class="container text-center">
        <a href={FACEBOOK_LINK} target="_blank" title={t('facebook_1motor')} class="text-white me-4">
          <i class="fab fa-facebook-f"></i>
        </a>
        <a href={INSTAGRAM_LINK} target="_blank" title={t('instagram_1motor')} class="text-white me-4">
          <i class="fab fa-instagram"></i>
        </a>
        <small title="">Copyright &copy; 1Motor.Ro</small>
      </div>
    </footer>
  </React.Fragment>
}