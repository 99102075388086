import React from 'react';
import { useTranslation } from 'react-i18next';
import { User } from '../components/content';
import { LINKS } from '../links';

export const UserAccountPage = () => {
    const { t } = useTranslation(['labels']);

    return <React.Fragment>
        <a href={LINKS.HOME} title={`${t('1motor')}`} className="fw-bold"><i className="fa fa-arrow-left" aria-hidden="true"></i> {`${t('back_to_main')}`}</a>
        <br />
        <User />
    </React.Fragment>
}