import axios from 'axios';
import React, { Fragment, useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { deleteCar, saveOrUpdateCar, searchForCars } from '../../services/car.service';
import Loading from '../../img/loading.gif';
import { API, LINKS } from '../../links';
import { CarTypeGuide } from './car-type-guide';
import { useTranslation } from 'react-i18next';

const CarFormInternal = (props) => {
  var history = useHistory();

  useEffect(() => {
    if (props.carId) {
      // fetch car info
      searchForCars(`id=${props.carId}`).then(data => {
        if (data && data.rows.length == 1) {
          var carFound = data.rows[0]; // take the first car
          filterBrandModels(carFound.brand_id);
          setCarInfo({
            ...carInfo,
            brand: carFound.brand_id,
            model: carFound.model_id,
            dealer: carFound.dealer_id,
            year: carFound.year,
            price: carFound.price,
            description: carFound.description,
            fuelType: carFound.fuel_type,
            carType: carFound.car_type,
            cc: carFound.cc,
            hp: carFound.hp,
            km: carFound.km,
            transmission: carFound.transmission,
            location_id: carFound.location_id
          });
        } else {
          alert('Anunțul nu a fost găsit')
        }
      })
    }
  }, []);

  const [carInfo, setCarInfo] = useState({
    dealer: '',
    brand: '',
    model: '',
    location_id: '',
    year: '',
    price: '',
    description: '',
    fuelType: '',
    carType: '',
    cc: '',
    hp: '',
    transmission: '',
    mainImage: '',
    images: []
  })

  const [currentBrandModels, setCurrentBrandModels] = useState([]);
  const [showLoading, setShowLoading] = useState(false);

  const filterBrandModels = (brandId) => {

    setCurrentBrandModels(props.models?.filter(model => model.brand_id == brandId));
  }

  const saveCar = () => {
    debugger
    if (!carInfo.dealer) {
      alert('EROARE: Selectati dealerul de care apartine autoturismul!');
      return;
    }
    if (!carInfo.brand || !carInfo.model) {
      alert('EROARE: Selectati marca si modelul autoturismului!');
      return;
    }
    if (!carInfo.year) {
      alert('EROARE: Adaugati anul fabricatiei autoturismului!');
      return;
    }
    if (!carInfo.location_id) {
      alert('EROARE: Adaugati locatia fabricatiei autoturismului!');
      return;
    }
    if (!carInfo.price || isNaN(carInfo.price)) {
      alert('EROARE: Adaugati pretul autoturismului!');
      return;
    }
    if (!carInfo.fuelType) {
      alert('EROARE: Adaugati tipul carburantului!');
      return;
    }
    if (!carInfo.transmission || carInfo.transmission == "0") {
      alert('EROARE: Adaugati tipul cutiei de viteze!');
      return;
    }
    if (!carInfo.carType) {
      alert('EROARE: Adaugati tipul autoturismului!');
      return;
    }
    if (!carInfo.description) {
      alert('EROARE: Adaugati descrierea autorusimului!');
      return;
    }
    if (!props.carId && !carInfo.mainImage) {
      alert('EROARE: Adaugati poza principala a autoturismului!');
      return;
    }
    if (!props.carId && (!carInfo.images || carInfo.images.length == 0)) {
      alert('EROARE: Adaugati pozele autoturismului!');
      return;
    }
    if (carInfo.cc && isNaN(carInfo.cc)) {
      alert('EROARE: Capacitatea cilindrica e gresita!');
      return;
    }
    if (carInfo.hp && isNaN(carInfo.hp)) {
      alert('EROARE: Puterea e gresita!');
      return;
    }
    if (carInfo.km && isNaN(carInfo.km)) {
      alert('EROARE: Kilometri sunt gresiti!');
      return;
    }

    // BUILD FORM DATA
    var data = new FormData(document.getElementById('car-form'));
    // add images
    if (carInfo.mainImage) {
      data.append('image0', carInfo.mainImage);
    }
    Array.from(carInfo.images).forEach((file, index) => {
      data.append(`image${index + 1}`, file);
    })
    setShowLoading(true);
    saveOrUpdateCar(data, props.loggedUser).then(
      data => {
        history.replace(`${LINKS.CAR_SEARCH}?id=${data}`)
      }
    ).catch(error => {
      debugger
      alert('EROARE');
    })
  }

  const del = () => {
    if (window.confirm("ATENȚIE!!!! Anunțul va fi șters. Esti sigur")) {
      var data = new FormData();
      data.append('carId', props.carId);
      deleteCar(data, props.loggedUser).then(data => {
        alert('Anuntul a fost sters');
        history.push(LINKS.ADD);
        window.location.reload();
      }).catch(error => {
        alert('EROARE');
      });
    }
  }
  return (
    <Fragment>
      <form id="car-form">
        {props.carId &&
          <Fragment>
            <input type="hidden" name="carId" value={props.carId} />
          </Fragment>
        }
        <input type="hidden" name="user" value={props.loggedUser?.id} />
        <div className="container p-3">
          <div className="section p-3">
            <div className="row">
              <div className="col">
                <h3>{props.carId ? 'Modifică' : 'Adaugă'} mașină</h3>
              </div>
              <div className="col">
              </div>
            </div>
            <div className="row pt-3">
              <div className="col">Dealer:</div>
              <div className="col">
                <Form.Control id="dealer" name="dealer" title="Marca autoturismului" as="select" onChange={(event) => setCarInfo({ ...carInfo, [event.target.id]: event.target.value })} custom>
                  <option>Selectați un dealer</option>
                  {props.dealers && props.dealers.map(element => <option selected={carInfo.dealer == element.id ? 'selected' : false} key={element.id} value={element.id} id={element.id}>{element.name}</option>)}
                </Form.Control>
              </div>
            </div>
            <div className="row pt-3">
              <div className="col">Marca:</div>
              <div className="col">
                <Form.Control id="brand" name="brand" title="Marca autoturismului" as="select" onChange={(event) => {
                  setCarInfo({ ...carInfo, [event.target.id]: event.target.value });
                  filterBrandModels(event.target.value);
                }} custom>
                  <option>Selectați o marcă</option>
                  {props.brands && props.brands.map(element => <option selected={carInfo.brand == element.id ? 'selected' : false} key={element.id} value={element.id} id={element.id}>{element.name}</option>)}
                </Form.Control>
              </div>
            </div>
            <div className="row pt-3">
              <div className="col">Model:</div>
              <div className="col">
                <Form.Control id="model" name="model" title="Modelul autoturismului" as="select" onChange={(event) => setCarInfo({ ...carInfo, [event.target.id]: event.target.value })} custom>
                  {!carInfo.brand &&
                    <option>Selectați întâi o marcă</option>
                  }
                  {carInfo.brand &&
                    <Fragment>
                      <option>Selectați un model</option>
                      {props.brands.find(b => b.id == carInfo.brand)?.models.map(element => <option selected={carInfo.model == element.id ? 'selected' : false} key={element.id} value={element.id}>{element.name}</option>)}
                    </Fragment>
                  }
                </Form.Control>
              </div>
            </div>
            <div className="row pt-3">
              <div className="col"><b className="text-danger">Câmp nou!</b> Locatia masinii:</div>
              <div className="col">
                <Form.Control id="location_id" name="location_id" title="Marca autoturismului" as="select" onChange={(event) => {
                  setCarInfo({ ...carInfo, [event.target.id]: event.target.value });
                }} custom>
                  <option>Selectați o locatie</option>
                  {props.locations && props.locations.map(element => <option selected={carInfo.location_id == element.id ? 'selected' : false} key={element.id} value={element.id} id={element.id}>{element.name}, judet: {element.county}</option>)}
                </Form.Control>
              </div>
            </div>
            <div className="row pt-3">
              <div className="col">
                <span style={{ fontSize: '10px' }}>* Daca marca sau modelul nu exista in lista, el trebuie adaugat de Bogdan. Contact: 0724255496 sau bogdanbejan89@gmail.com</span>
              </div>
            </div>
            <div className="row pt-3">
              <div className="col-sm">
                An de fabricație:
              </div>
              <div className="col-sm">
                <Form.Control id="year" name="year" title="Anul de fabricație al autoturismului" onChange={(event) => setCarInfo({ ...carInfo, [event.target.id]: event.target.value })} as="select" custom>
                  <option value="0">Selectați</option>
                  <option selected={carInfo.year == 2022 ? 'selected' : false} value="2022">2022</option>
                  <option selected={carInfo.year == 2021 ? 'selected' : false} value="2021">2021</option>
                  <option selected={carInfo.year == 2020 ? 'selected' : false} value="2020">2020</option>
                  <option selected={carInfo.year == 2019 ? 'selected' : false} value="2019">2019</option>
                  <option selected={carInfo.year == 2018 ? 'selected' : false} value="2018">2018</option>
                  <option selected={carInfo.year == 2017 ? 'selected' : false} value="2017">2017</option>
                  <option selected={carInfo.year == 2016 ? 'selected' : false} value="2016">2016</option>
                  <option selected={carInfo.year == 2015 ? 'selected' : false} value="2015">2015</option>
                  <option selected={carInfo.year == 2014 ? 'selected' : false} value="2014">2014</option>
                  <option selected={carInfo.year == 2013 ? 'selected' : false} value="2013">2013</option>
                  <option selected={carInfo.year == 2012 ? 'selected' : false} value="2012">2012</option>
                  <option selected={carInfo.year == 2011 ? 'selected' : false} value="2011">2011</option>
                  <option selected={carInfo.year == 2010 ? 'selected' : false} value="2010">2010</option>
                </Form.Control>
              </div>
            </div>
            <div className="row pt-3">
              <div className="col-sm">
                Pret(in EURO):
              </div>
              <div className="col-sm">
                <Form.Control id="price" title="Pret" name="price" value={carInfo.price} onChange={(event) => setCarInfo({ ...carInfo, [event.target.id]: event.target.value })} as="input" custom />
              </div>
            </div>
            <div className="row pt-3">
              <div className="col-sm">
                Kilometri:
              </div>
              <div className="col-sm">
                <Form.Control id="km" title="Pret" name="km" value={carInfo.km} onChange={(event) => setCarInfo({ ...carInfo, [event.target.id]: event.target.value })} as="input" custom />
              </div>
            </div>
            <div className="row pt-3">
              <div className="col-sm">
                Carburant:
              </div>
              <div className="col-sm">
                <Form.Control id="fuelType" title="" name="fuelType" onChange={(event) => setCarInfo({ ...carInfo, [event.target.id]: event.target.value })} as="select" custom>
                  <option value="">Selectați</option>
                  {props.fuelTypes && props.fuelTypes.map(element => <option selected={carInfo.fuelType == element.id ? 'selected' : false} key={element.id} value={element.id}>{element.name}</option>)}
                </Form.Control>
              </div>
            </div>
            <div className="row pt-3">
              <div className="col-sm">
                Tipul masinii:
              </div>
              <div className="col-sm">
                <Form.Control id="carType" title="" name="carType" onChange={(event) => setCarInfo({ ...carInfo, [event.target.id]: event.target.value })} as="select" custom>
                  <option value="">Selectați</option>
                  {props.carTypes && props.carTypes.map(element => <option selected={carInfo.carType == element.id ? 'selected' : false} key={element.id} value={element.id}>{element.name}</option>)}
                </Form.Control>
                <p className="text-danger font-weight-bold">În cazul in care tipul mașinii nu există, el trebuie adaugat de Bogdan.</p>
                <button className="btn btn-primary mb-4" type="button" data-toggle="modal" data-target="#carTypeGuide" >
                  Arata ghidul pentru tipul de masina
                </button>
                <div className="modal fade" id="carTypeGuide" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                  <div className="modal-dialog  modal-lg modal-dialog-centered w-100" role="document">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLongTitle">Tipul autoturismului</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div className="modal-body text-left">
                        {props.carTypes.map(type => <div className="row"><CarTypeGuide type={type} /></div>)}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row pt-3">
              <div className="col-sm">
                Capacitate cilindrică:
              </div>
              <div className="col-sm">
                <Form.Control id="cc" title="cc" name="cc" value={carInfo.cc} onChange={(event) => setCarInfo({ ...carInfo, [event.target.id]: event.target.value })} as="input" custom />
              </div>
            </div>
            <div className="row pt-3">
              <div className="col-sm">
                <b className="text-danger">Câmp nou!</b>
                Putere (CP):
              </div>
              <div className="col-sm">
                <Form.Control id="hp" title="hp" name="hp" value={carInfo.hp} onChange={(event) => setCarInfo({ ...carInfo, [event.target.id]: event.target.value })} as="input" custom />
              </div>
            </div>
            <div className="row pt-3">
              <div className="col-sm">
                Cutie de viteze:
              </div>
              <div className="col-sm">
                <Form.Control id="transmission" name="transmission" value={carInfo.transmission} title="cutia de viteze" onChange={(event) => setCarInfo({ ...carInfo, [event.target.id]: event.target.value })} as="select" custom>
                  <option value="0">Selectați</option>
                  <option selected={carInfo.transmission == 'automată' ? 'selected' : false} value="automată">automată</option>
                  <option selected={carInfo.transmission == 'manuală' ? 'selected' : false} value="manuală">manuală</option>
                </Form.Control>
              </div>
            </div>
            <div className="row pt-3">
              <div className="col-sm">
                Descriere:
              </div>
              <div className="col-sm">
                <textarea id="description" name="description" value={carInfo.description} onChange={(event) => setCarInfo({ ...carInfo, [event.target.id]: event.target.value })} className="w-100" rows="6" />
              </div>
            </div>
            {!props.carId &&
              <Fragment>
                <div className="row pt-3">
                  <div className="col-sm">
                    {/* <b className="text-danger">ATENȚIE: ADĂUGAREA DE POZE VA SUPRASCRIE TOATE POZELE EXISTENTE!!!</b>
                  <b className="text-danger">ÎN CAZUL ÎN CARE NU DORIȚI SĂ MODIFICAȚI POZELE EXISTENTE, LĂSAȚI CÂMPURILE URMÂTOARE GOALE.</b> */}
                  </div>
                </div>

                <div className="row pt-3">
                  <div className="col-sm">
                    Poza principala:
                  </div>
                  <div className="col-sm">
                    <input type="file" id="mainImage" onChange={(event) => setCarInfo({ ...carInfo, [event.target.id]: event.target.files[0] })} accept="image/png, image/jpeg" />
                  </div>
                </div>
                <div className="row pt-3">
                  <div className="col">
                    <span style={{ fontSize: '10px' }}>* Fiecare masina are nevoie de o poza principala, de preferat una care scoate masina cat mai in evidenta.</span>
                  </div>
                </div>
                <div className="row pt-3">
                  <div className="col-sm">
                    Poze:
                  </div>
                  <div className="col-sm">
                    <input type="file" id="images" onChange={(event) => setCarInfo({ ...carInfo, [event.target.id]: event.target.files })} accept="image/png, image/jpeg" multiple />
                  </div>
                </div>
                <div className="row pt-3">
                  <div className="col">
                    <span style={{ fontSize: '10px' }}>* In acest camp se adauga toate restul pozelor. Pentru a alege mai multe fisiere se tine apasat butonul CTRL si se da click pe pozele dorite.</span>
                  </div>
                </div>
              </Fragment>
            }
            <div className="row pt-3">
              <div className="col-sm">
                <button onClick={saveCar} type="button" class="btn">{props.carId ? 'Modifică' : 'Adaugă'}</button>
              </div>
              <div className="col-sm">
                {props.carId &&
                  <button onClick={del} type="button" class="btn">Șterge mașina</button>
                }
              </div>
            </div>
          </div>
        </div>

        {showLoading &&
          <div className="d-flex" style={{ top: '0px', backgroundColor: 'rgba(255,255,255,0.8', position: 'absolute', width: '100%', height: '100%' }}>
            <img style={{ margin: 'auto', display: 'block' }} src={Loading} />
          </div>
        }
      </form>
    </Fragment>
  )
}

const mapStateToProps = (state) => {
  return {
    brands: state.brands,
    models: state.models,
    dealers: state.dealers,
    carTypes: state.carTypes,
    fuelTypes: state.fuelTypes,
    locations: state.locations,
    loggedUser: state.loggedUser
  }
}

export const CarForm = connect(mapStateToProps)(CarFormInternal);