import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

const CarTypeInternal = ({carTypes, carType}) => {
    const { t } = useTranslation(['labels']);

	var img;
	if (carTypes && carType) {
		var iconName = '';
		if (Number.isInteger(parseInt(carType))) {
			iconName = carTypes?.filter(c => c.id === parseInt(carType))[0]?.icon
		} else {
			iconName = carType;
		}
		img = <img title={`${t('car_type').toUpperCase()}: ${iconName?.toUpperCase()}`} class="img-fluid" src={require(`../../img/car-type/${iconName}.png`).default} />
	}
	return <div>{img}
	</div>
}
// LOAD REDUX DATA AS PROPS
const mapStateToProps = (state) => {
    return {
        carTypes: state.carTypes
    }
}
export const CarType = connect(mapStateToProps)(CarTypeInternal);