import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';


export const SelectCc = ({ handleSelectCc }) => {
    const { t } = useTranslation(['labels']);
    const [state, setState] = useState({
        ccMin: 0,
        ccMax: 0
    });
    let queryParams = new URLSearchParams(window.location.search);

    useEffect(() => {
        if (queryParams.get('ccMin')) {
            setState({...state, ccMin: queryParams.get('ccMin')})
        }
        if (queryParams.get('ccMax')) {
            setState({...state, ccMax: queryParams.get('ccMax')})
        }
    }, []);

    const onChangeccMin = (ccMin) => {
        ccMin = parseInt(ccMin);
        setState({ ...state, ccMin});
        handleSelectCc({ ccMin, ccMax: state.ccMax });
    }

    const onChangeccMax = (ccMax) => {
        ccMax = parseInt(ccMax);
        setState({ ...state, ccMax});
        handleSelectCc({ ccMin: state.ccMin, ccMax });
    }

    var ccOptions = Array.of(1000, 1500, 2000, 2500, 3000, 4000, 5000);

    return <div class="text-left">
        <h5>{t('cc_select')}:</h5>
        <div>
        <div class="input-group-prepend input-group">
            <button class="btn dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> </button>
            <div class="dropdown-menu">
                {ccOptions.map(cc =>
                    <a key={cc} class="dropdown-item" onClick={() => onChangeccMin(cc)}>{cc}</a>
                )}
            </div>
            <input type="text" class="input dropdown-input form-control" onChange={(e) => onChangeccMin(e.target.value)} value={state.ccMin > 0 ? state.ccMin : ''} placeholder={t('cc_min')} aria-label="Text input with dropdown button" />
        </div>
        <div class="input-group-prepend input-group mt-3">
            <button class="btn dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> </button>
            <div class="dropdown-menu">
                {ccOptions.map(cc =>
                    <a key={cc} class="dropdown-item" onClick={() => onChangeccMax(cc)}>{cc}</a>
                )}
            </div>
            <input type="text" class="input dropdown-input form-control" onChange={(e) => onChangeccMax(e.target.value)} value={state.ccMax > 0 ? state.ccMax : ''} placeholder={t('cc_max')} aria-label="Text input with dropdown button" />
        </div>
        </div>
    </div>
}