import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import '../../css/user.scss';
import { LINKS } from '../../links';
import { LoggedUser } from '../../redux/LoggedUser';
import { sendPasswordRecoveryEmail, changeRecoveredPassword } from '../../services/user.service';
import { UserLogin } from './UserLogin';
import { UserMenu } from './UserMenu';

interface RecoverPasswordProps {
    loggedUser: LoggedUser,
}

interface RecoverPasswordState {
    email: string,
    password: string,
    confirmPassword: string,
    passwordsMissmatch: boolean
}

const RecoverPasswordInternal = ({ loggedUser }: RecoverPasswordProps) => {
    const { t } = useTranslation(['labels']);
    let history = useHistory();

    let queryParams = new URLSearchParams(window.location.search);
    const [state, setState] = useState<RecoverPasswordState>({
        email: '',
        password: '',
        confirmPassword: '',
        passwordsMissmatch: false
    })

    const sendRecoveryEmail = () => {
        if (!state.email) {
            alert(t('add_email'));
        }
        sendPasswordRecoveryEmail(state.email)
            .then(() => {
                alert(t('recovery_mail_sent'));
                history.push(LINKS.HOME);
            }).catch(error => {
                alert(t('something_failed'));
            })
    }
    let hash = queryParams.get('hash');

    const changePassword = () => {
        if (!state.password || state.password !== state.confirmPassword) {
            setState({ ...state, passwordsMissmatch: true })
        }
        if (!hash) return;
        changeRecoveredPassword(state.password, hash)
            .then(() => {
                alert(t('password_changed'));
                history.push(LINKS.HOME);
            }).catch(error => {
                alert(t('something_failed'));
            })
    }

    if (loggedUser) {
        history.push('/');
    }

    return <>
        <div className="user-container">
            <div className="row">
                <div className="col">
                    <h3>{`${t('recover_password')}`}</h3>
                </div>
            </div>
            {hash
                ?
                <>
                    <div className="row">
                        <div className="col d-flex align-items-center">
                            {`${t('password')}`}
                        </div>
                        <div className="col">
                            <input onChange={(e) => setState({ ...state, [e.target.id]: e.target.value })} id="password" type="password" className="input" value={state.password} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col d-flex align-items-center">
                            {`${t('confirm_password')}`}
                        </div>
                        <div className="col">
                            <input onChange={(e) => setState({ ...state, [e.target.id]: e.target.value })} id="confirmPassword" type="password" className="input" value={state.confirmPassword} />
                        </div>
                    </div>
                    {state.passwordsMissmatch &&
                        <div className="row">
                            <div className="col text-danger">
                                {`${t('passwords_missmatch')}`}
                            </div>
                        </div>
                    }
                    <div className="row">
                        <div className="col">
                            <button onClick={() => changePassword()} type="button" className="btn">{`${t('apply_change')}`}</button>
                        </div>
                    </div>

                </>
                :
                <>
                    <div className="row">
                        <div className="col-2 d-flex align-items-center">
                            {`${t('email')}`}
                        </div>
                        <div className="col">
                            <input onChange={(e) => setState({ ...state, [e.target.id]: e.target.value })} id="email" type="text" className="input" value={state.email} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <button onClick={() => sendRecoveryEmail()} type="button" className="btn">{`${t('send_recovery_email')}`}</button>
                        </div>
                    </div>
                </>
            }
        </div>
    </>
}
// LOAD REDUX DATA AS PROPS
const mapStateToProps = (state: any) => {
    return {
        loggedUser: state.loggedUser
    }
}
export const RecoverPassword = connect(mapStateToProps)(RecoverPasswordInternal);
