export const fuelTypes = [
    {id: 1, label: 'fuel_benzine', title: 't_fuel_benzine'},
    {id: 2, label: 'fuel_diesel', title: 't_fuel_diesel'},
    {id: 3, label: 'fuel_gpl', title: 't_fuel_gpl'},
    {id: 4, label: 'fuel_hybrid', title: 't_fuel_hybrid'},
    {id: 5, label: 'fuel_electric', title: 't_fuel_electric'},

]

export const gearBoxes = [
    {id: 1, value: 'manuală', label: 'gearbox_manual', title: 't_gearbox_manual'},
    {id: 2, value: 'automată', label: 'gearbox_automatic', title: 't_gearbox_automatic'},
]

export const sorting = [
    {id: 1, label: 'date_desc', title: 't_gearbox_manual'},
    {id: 2, label: 'date_asc', title: 't_gearbox_automatic'},
    {id: 3, label: 'sorting_price_desc', title: 't_gearbox_manual'},
    {id: 4, label: 'sorting_price_asc', title: 't_gearbox_automatic'},
]

export const bodyType = [
    {id: 1, label: 'date_desc', title: 't_gearbox_manual'},
    {id: 2, label: 'date_asc', title: 't_gearbox_automatic'},
    {id: 3, label: 'sorting_price_desc', title: 't_gearbox_manual'},
    {id: 4, label: 'sorting_price_asc', title: 't_gearbox_automatic'},
]

export const GOOGLE_TOKENID = 'GOOGLE_TOKENID';
export const EMAIL = "BASIC_AUTH_EMAIL";
export const PWD = "BASIC_AUTH_PWD";
export const AUTH = 'AUTH';