import React, { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { loadCarTypes } from '../../redux';

export const SelectCarTypeInternal = ({ handleSelectCarType, carTypes }) => {
    const { t } = useTranslation(['labels']);
    const [state, setState] = useState({
        carType: [],
        carTypes: []
    });

    useEffect(() =>{
        loadCarTypes();
        if (carTypes?.length > 0) {
            setState({...state, carTypes});
        }
    }, [carTypes])

    const toggleCarType = (selectedCarType) => {
        var carType = state.carType;
        if (selectedCarType) {
            var carTypeIndex = carType.findIndex(f => f.id === selectedCarType.id);
            if (carTypeIndex > -1) {
                carType.splice(carTypeIndex, 1);
            } else {
                carType.push(selectedCarType);
            }
        } else {
            // reset
            carType = [];
        }
        setState({ ...state, carType });
        handleSelectCarType(carType.slice());
    }

    return <div class="text-left w-100">
        <h5>{t('car_type_select')}:</h5>
        <div class="list-group" style={{maxHeight: '350px', overflowY: 'auto'}}>
            <a class="list-group-item" onClick={() => toggleCarType()}>{state.carType.length === 0 ? <i class="fa-solid fa-circle-check"></i> : ''}{t('fuel_all')}</a>
            {state.carTypes.map(carType =>
                <a key={carType.id} class="list-group-item" onClick={() => toggleCarType(carType)}><img height={35} src={require('../../img/car-type/' + carType.icon + '.png').default} />{state.carType?.includes(carType) ? <i class="fa-solid fa-circle-check"></i> : ''}{isMobile && <br />}{carType.name}</a>
            )}
        </div>

    </div>
}

const mapStateToProps = (state) => {
    return {
        carTypes: state.carTypes
    }
}

export const SelectCarType = connect(mapStateToProps)(SelectCarTypeInternal);