import axios from 'axios';
import React, { useState } from 'react';
import Modal from 'react-bootstrap/esm/Modal';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { API, DOMAIN, LINKS } from '../../links';
import { ICar } from '../../models/car';
import { LoggedUser } from '../../redux/LoggedUser';

interface ShareProps {
    car: ICar,
    loggedUser: LoggedUser
}
interface ShareState {
    contact: string,
    isMail: boolean
}

const ShareInternal = ({ car, loggedUser }: ShareProps) => {
    const { t } = useTranslation(['labels']);
    const [state, setState] = useState<ShareState>({
        contact: '',
        isMail: true
    })

    const sendEmail = () => {
        let link = `${DOMAIN}${LINKS.CAR_SEARCH}?id=${car.car_id}`;
        if (state.isMail) {
            if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(state.contact)) {
                let link = `${DOMAIN}${LINKS.CAR_SEARCH}?id=${car.car_id}`;
                axios({
                    method: 'post',
                    url: API.SHARE,
                    data: { email: state.contact, link, car: `${car.brand} ${car.model}` }
                }).then(response => {
                    alert(t('sent'))
                });
            } else {
                alert(t('invalid_email'));
            }
        } else {
            if(state.contact.startsWith("07") && state.contact.length == 10 ) {
                window.open(`https://wa.me/+4${state.contact}?text=${link}`, '_blank');
            }            else {
                alert(t('invalid_number'));
            }
        }
    }

    const handleChange = (e: any) => {
        debugger
        if (e.target.value.startsWith("0") && e.target.value.replace(/ /g, '').match(/[^\d]/) == null) {
            setState({ ...state, contact: e.target.value.replace(/ /g, ''), isMail: false });
        } else {
            setState({ ...state, contact: e.target.value.replace(/ /g, ''), isMail: true });
        }
    }

    return <>
        <div className="container-fluid">
            <div className="row">
                <div className="col">
                    {`${isMobile ? t('share_email_whatsapp_address') : t('share_email_address')}`}:
                </div>
            </div>
            <div className="row">
                <div className="col-1 display-6 d-flex align-items-center">
                    {state.isMail ?
                        <i className="fa fa-envelope" />
                        :
                        <i className="fa-brands fa-whatsapp" />
                    }
                </div>
                <div className="col">
                    <input type="text" className="input w-100" value={state.contact} onChange={(e) => handleChange(e)} ></input>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <button type="button" className="btn btn-primary" data-bs-dismiss="modal" onClick={() => sendEmail()}>{`${t('send')}`}</button>
                </div>
            </div>
        </div>
    </>
}
// LOAD REDUX DATA AS PROPS
const mapStateToProps = (state: any) => {
    return {
        brands: state.brands,
        fuelTypes: state.fuelTypes,
        carTypes: state.carTypes,
        locations: state.locations,
        loggedUser: state.loggedUser
    }
}
export const Share = connect(mapStateToProps)(ShareInternal);

