
import React, { ReactElement, useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import '../../css/car.scss';
import { API } from '../../links';
import { ICar } from '../../models/car';
import { IImage } from '../../models/image';
import { CarContact as CarContact } from './CarContact';
import { CarFinanceCalculator } from './CarFinanceCalculator';
import { Share } from './Share';

interface CarButtonsProps {
    car: ICar
}

interface CarButtonsState {
    showDescription: boolean,
    showShare: boolean,
    showCarContact: boolean,
    showCalculator: boolean
}

const CarButtonsInternal = ({ car }: CarButtonsProps) => {
    const { t } = useTranslation(['labels']);
    const [state, setState] = useState<CarButtonsState>({
        showDescription: false,
        showShare: false,
        showCarContact: false,
        showCalculator: false
    })

    const description = splitDescription(car.description);
    
    let colClass = isMobile? "col" : "col-7 mx-auto";
    
    return <>
        <div className="container-fluid">
            <div className={`row mt-1 ${state.showShare ? ' bg-secondary' : ""}`}>
                <div className={colClass}>
                    <button onClick={() => setState({ ...state, showShare: !state.showShare })} title={t('share_title')} type="button" className="btn btn-secondary w-100">{`${t('share')}`}</button>
                    {state.showShare && <Share car={car} />}
                </div>
            </div>
            <div className={`row mt-1  ${state.showDescription ? ' bg-secondary' : ""}`}>
                <div className={colClass}>
                    <button onClick={() => setState({ ...state, showDescription: !state.showDescription })} title={t('technical_details')} type="button" className="btn btn-secondary w-100">{`${t('technical_details')}`}</button>
                    {state.showDescription &&
                        <>
                            {isMobile
                                ? <pre className="overflow-hidden text-left text-white">{car.description}</pre>
                                : <div className="row">
                                    <div className="col-6">
                                        <pre className="overflow-hidden text-left text-white">{description.column1}</pre>
                                    </div>
                                    <div className="col-6">
                                        <pre className="overflow-hidden text-left text-white">{description.column2}</pre>
                                    </div>
                                </div>
                            }
                        </>
                    }
                </div>
            </div>
            <div className={`row mt-1 ${state.showCalculator ? ' bg-secondary' : ""}`}>
                <div className={colClass}>
                    <button onClick={() => setState({ ...state, showCalculator: !state.showCalculator })} title={t('finance')} type="button" className="btn btn-secondary w-100">{`${t('finance')}`}</button>
                    {state.showCalculator && <CarFinanceCalculator car={car} />}
                </div>
            </div>
            <div className={`row mt-1 ${state.showCarContact ? ' bg-secondary' : ""}`}>
                <div className={colClass}>
                    <button onClick={() => setState({ ...state, showCarContact: !state.showCarContact })} title={t('interesed')} type="button" className="btn btn-secondary w-100">{`${t('interesed')}`}</button>
                    {state.showCarContact && <CarContact car={car} />}
                </div>
            </div>
        </div>
    </>
}
// LOAD REDUX DATA AS PROPS
const mapStateToProps = (state: any) => {
    return {
        locations: state.locations,
        loggedUser: state.loggedUser,
        fuelTypes: state.fuelTypes,
        carTypes: state.carTypes
    }
}
export const CarButtons = connect(mapStateToProps)(CarButtonsInternal);

/**
 * Split the description on to two columns for better display on wide screens.
 * 
 */
const splitDescription = (description: string) => {
    let splitDescription = {
        column1: '',
        column2: ''
    };
    if (description) {
        let rows = (description.match(/\n/g) || []).length;
        let splitIndex = indexOfNth(description, '\n', Math.ceil(rows / 2));
        splitDescription.column1 = description.substring(0, splitIndex);
        splitDescription.column2 = description.substring(splitIndex + 1);
    }
    return splitDescription;
}
/**
 * Finds the nth occurance of char within the string.
 */
const indexOfNth = (string: string, char: string, nth: number, fromIndex: number = 0): number => {
    let indexChar = string.indexOf(char, fromIndex);
    if (indexChar === -1) {
        return -1;
    } else if (nth === 1) {
        return indexChar;
    } else {
        return indexOfNth(string, char, nth - 1, indexChar + 1);
    }
}