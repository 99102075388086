import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';


export const SelectYear = ({ handleSelectYear }) => {
    const { t } = useTranslation(['labels']);
    const [state, setState] = useState({
        yearMin: 0,
        yearMax: 0
    });
    let queryParams = new URLSearchParams(window.location.search);

    useEffect(() => {
        if (queryParams.get('yearMin')) {
            setState({...state, yearMin: queryParams.get('yearMin')})
        }
        if (queryParams.get('yearMax')) {
            setState({...state, yearMax: queryParams.get('yearMax')})
        }
    }, []);

    const onChangeYearMin = (yearMin) => {
        yearMin = parseInt(yearMin);
        setState({ ...state, yearMin});
        handleSelectYear({ yearMin, yearMax: state.yearMax });
    }

    const onChangeYearMax = (yearMax) => {
        yearMax = parseInt(yearMax);
        setState({ ...state, yearMax});
        handleSelectYear({ yearMin: state.yearMin, yearMax });
    }

    var yearOptions = [];
    const currentYear = new Date().getFullYear();
    for (var i = currentYear; i >= currentYear - 10; i--) {
        yearOptions.push(i);
    }

    return <div class="text-left">
        <h5>{t('year_select')}:</h5>
        <div>
        <div class="input-group-prepend input-group">
            <button class="btn dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> </button>
            <div class="dropdown-menu">
                {yearOptions.map(year =>
                    <a key={year} class="dropdown-item" onClick={() => onChangeYearMin(year)}>{year}</a>
                )}
            </div>
            <input type="text" class="input dropdown-input form-control" onChange={(e) => onChangeYearMin(e.target.value)} value={state.yearMin > 0 ? state.yearMin : ''} placeholder={t('year_min')} aria-label="Text input with dropdown button" />
        </div>
        <div class="input-group-prepend input-group mt-3">
            <button class="btn dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> </button>
            <div class="dropdown-menu">
                {yearOptions.map(year =>
                    <a key={year} class="dropdown-item" onClick={() => onChangeYearMax(year)}>{year}</a>
                )}
            </div>
            <input type="text" class="input dropdown-input form-control" onChange={(e) => onChangeYearMax(e.target.value)} value={state.yearMax > 0 ? state.yearMax : ''} placeholder={t('year_max')} aria-label="Text input with dropdown button" />
        </div>
        </div>
    </div>
}