
import React, { ReactElement, useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import '../../css/car.scss';
import { API } from '../../links';
import { ICar } from '../../models/car';
import { IImage } from '../../models/image';

interface CarFinanceProps {
    car: ICar,
}

interface CarFinanceState {
    period: number,
    downPayment: number,
    periodInvalid: boolean,
    paymentInvalid: boolean,
    rate: number
}

const CarFinanceCalculatorInternal = ({ car }: CarFinanceProps) => {
    const { t } = useTranslation(['labels']);

    const [state, setState] = useState<CarFinanceState>({
        period: 0,
        downPayment: 0,
        periodInvalid: false,
        paymentInvalid: false,
        rate: 0
    });

    const calculate = () => {
        console.table(state);
        if (state.period < 1 || state.period > 6 || (state.period + new Date().getFullYear() - car.year) >10) {
            setState({ ...state, periodInvalid: true, paymentInvalid: false, period: 0, rate: 0 })
            return;
        }
        debugger
        if (state.downPayment < (car.price * 0.1) || state.downPayment >= car.price) {
            setState({ ...state, paymentInvalid: true, periodInvalid: false, downPayment: 0, rate: 0 })
            return;
        }
        let rate = (car.price - state.downPayment) * (1.04) / (state.period * 12);
        setState({ ...state, rate: rate, paymentInvalid: false, periodInvalid: false })
    }

    return <>
        <div className="container">
            <div className="row">
                <div className="col d-flex align-items-center"><b>{`${t('interest_from')}`}</b></div>
            </div>
            <div className="row">
                <div className="col d-flex align-items-center">{`${t('period')}`}</div>
                <div className="col">
                    <input type="text" className="input" id="period" value={state.period} onChange={(e) => setState({ ...state, [e.target.id]: parseInt(e.target.value) || 0 })} />
                </div>
            </div>
            {state.periodInvalid &&
                <div className="row text-danger">
                    <div className="col d-flex align-items-center"><b>{`${t('period_invalid')}`}</b></div>
                </div>
            }
            <div className="row">
                <div className="col d-flex align-items-center">{`${t('down_payment')}`}</div>
                <div className="col">
                    <input type="text" className="input" id="downPayment" value={state.downPayment} onChange={(e) => setState({ ...state, [e.target.id]: parseInt(e.target.value) || 0 })} />
                </div>
            </div>
            {state.paymentInvalid &&
                <div className="row text-danger">
                    <div className="col d-flex align-items-center"><b>{`${t('payment_invalid')}`}</b></div>
                </div>
            }
            {state.rate > 0 &&
                <div className="row">
                    <div className="col d-flex align-items-center"><b>{`${t('monthly_rate')} : ${t('from')} ${state.rate.toFixed()} Euro / ${t('month')}`}</b></div>
                </div>
            }
            <div className="row">
                <div className="col">
                    <button type="button" className="btn btn-primary" onClick={calculate}>{`${t('calculate')}`}</button>
                </div>
            </div>
        </div>
    </>

}
// LOAD REDUX DATA AS PROPS
const mapStateToProps = (state: any) => {
    return {
        locations: state.locations,
        loggedUser: state.loggedUser,
        fuelTypes: state.fuelTypes,
        carTypes: state.carTypes
    }
}
export const CarFinanceCalculator = connect(mapStateToProps)(CarFinanceCalculatorInternal);