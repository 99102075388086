
import React, { useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import '../../css/car.scss';
import { API } from '../../links';
import { ICar } from '../../models/car';
import { CarButtons } from './CarButtons';
import { CarImages } from './CarImages';
import { CarReference } from './CarReference';

interface CarProps {
    car: ICar,
    fuelTypes: [],
    defaultOpen?: boolean
}

interface CarState {
    showDetails: boolean
}

const CarInternal = ({ car, fuelTypes, defaultOpen = false }: CarProps) => {
    const { t } = useTranslation(['labels']);
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has('id') && urlParams.get('id') == car.car_id) {
        defaultOpen = true;
    }
    const [state, setState] = useState<CarState>({
        showDetails: defaultOpen
    });

    let colClass = isMobile ? "col my-2" : "col-7 mx-auto my-2";

    let thumbnailSrc = `${API.IMAGE}carId=${car.images[0]?.folder_id}&fileName=${car.images[0]?.name}`;

    return <>
        <div className="car-content" >
            <div className={`row ${!state.showDetails ? 'main-row-hover':''}`} onClick={() => { if (!state.showDetails) setState({ ...state, showDetails: true }) }}>
                <div className={colClass}>
                    <div className="row">
                        {isMobile
                            ? <>
                                <div className="col-5">
                                    <img className="img-thumb" src={thumbnailSrc} />
                                </div>
                                <div className="col-6">
                                    <div className="row my-2">
                                        <div className="col text-center fw-bold border-bottom border-2 border-primary">
                                            <span>{car.brand} {car.model}</span>
                                            <br />
                                            {car.price.toLocaleString()} euro
                                            {state.showDetails &&
                                                <i role="button" title={`${t('close_details')}`} className="close-icon-mobile fa-regular fa-rectangle-xmark" onClick={() => setState({ ...state, showDetails: false })}></i>
                                            }
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col">
                                            {`${t(fuelTypes.filter((ft: any) => ft.id === car.fuel_type)[0]['label'])}`}
                                        </div>
                                        <div className="col">
                                            {`${car.transmission}`}
                                        </div>
                                    </div>

                                </div>
                            </>
                            :
                            <>
                                <div className="col-3">
                                    <img className="img-thumb" src={thumbnailSrc} />
                                </div>
                                <div className="col-9">
                                    <div className="row">
                                        <div className="offset-1 col-9 text-center fw-bold border-bottom border-2 border-primary">
                                            <span>{car.brand} {car.model}</span>
                                            <br />
                                            {car.price.toLocaleString()} euro
                                        </div>
                                        {state.showDetails &&
                                            <div className="col">
                                                <i role="button" title={`${t('close_details')}`} className="fa-regular fa-rectangle-xmark" onClick={() => setState({ ...state, showDetails: false })}></i>
                                            </div>
                                        }
                                    </div>
                                    <div className="row">
                                        <div className="offset-1 col-4 border-bottom border-2 border-primary">
                                            {`${t('fuel')}`}
                                        </div>
                                        <div className="offset-1 col-4 border-bottom border-2 border-primary">
                                            {`${t('gearbox')}`}
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="offset-1 col-4">
                                            {`${t(fuelTypes.filter((ft: any) => ft.id === car.fuel_type)[0]['label'])}`}
                                        </div>
                                        <div className="offset-1 col-4">
                                            {`${car.transmission}`}
                                        </div>
                                    </div>
                                </div>
                            </>
                        }
                    </div>
                </div>
            </div>
            {state.showDetails &&
                <>
                    <CarImages car={car} />
                    <CarReference car={car} />
                    <CarButtons car={car} />
                </>
            }
        </div>
    </>
}
// LOAD REDUX DATA AS PROPS
const mapStateToProps = (state: any) => {
    return {
        locations: state.locations,
        loggedUser: state.loggedUser,
        fuelTypes: state.fuelTypes
    }
}
export const Car = connect(mapStateToProps)(CarInternal);