import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { sorting } from '../../consts';


export const Sorting = ({ handleSorting }) => {
    const { t } = useTranslation(['labels']);
    const [state, setState] = useState({
        sorting
    });

    return <div class="text-center w-80 mt-2">
        <h5>{t('sort_select')}:</h5>
        <div class="list-group">
            {sorting.map(sort =>
                <a key={sort.id} class="list-group-item" onClick={() => {setState({sorting: sort}); handleSorting(sort)}}>{state.sorting?.id == sort.id? <i class="fa-solid fa-circle-check"></i> : ''}{t(sort.label)}</a>
            )}
        </div>

    </div>
}