
import React, { useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import '../../css/car.scss';
import { LINKS } from '../../links';
import { ICar } from '../../models/car';
import { LoggedUser } from '../../redux/LoggedUser';
import { toggleFavoriteCar } from '../../services/user.service';

interface CarImagesProps {
    car: ICar,
    carTypes: [],
    loggedUser: LoggedUser
}
interface CarImagesState {
    isFavorite: boolean
}

const CarReferenceInternal = ({ car, carTypes, loggedUser }: CarImagesProps) => {
    const { t } = useTranslation(['labels']);
    const [state, setState] = useState<CarImagesState>({
        isFavorite: loggedUser?.savedCars.filter(r => r == car.id).length > 0
    });

    const toggleFavorite = () => {
        if (loggedUser) {
            let newFavoriteStatus: boolean = !state.isFavorite;
            toggleFavoriteCar(newFavoriteStatus, car.id, loggedUser).then(response => {
                if (!response) {
                    alert(t('something_failed'))
                }
                setState({ ...state, isFavorite: newFavoriteStatus })
            }).catch(error => {
                console.log(error);
            })
            if (newFavoriteStatus) {
                loggedUser.savedCars.push(car.id);
            } else {
                loggedUser.savedCars = loggedUser.savedCars.filter(s => s !== car.id);
            }

            setState({ ...state, isFavorite: !state.isFavorite });
        } else {
            alert(t('e_not_logged_in'));
        }
    }

    let colClass = isMobile ? "col" : "col-7 mx-auto";
    return <>
        <div className="container">
            <div className="row">
                <div className={`${colClass} border-top border-3 border-primary fw-bold`}>
                    {loggedUser?.is_admin == 1 &&
                        <>
                            <div className="row">
                                <div className="col">
                                    <a href={`${LINKS.ADD}?id=${car.car_id}`}><button type="button" className="btn btn-secondary">Modifica</button></a>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col text-left">
                                    Dealer: {car.dealer_name}
                                    <br />
                                    Date: {car.date_added}
                                </div>
                            </div>
                        </>
                    }
                    <div className="row">
                        <div className="col-5 text-left">
                            {`${t('year')}: ${car.year}`}
                        </div>
                        <div className="offset-2 col-5 text-right">
                            {`${t('km')}: ${car.km.toLocaleString().replace(',', '.')}`}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-5 text-left">
                            {!isMobile && <span>{`${t('car_type')}:`}</span>} {`${t(carTypes.filter((ct: any) => ct.id === car.car_type)[0]['name'])}`}
                        </div>
                        <div className="offset-2 col-5 text-right">
                            <div className="custom-control custom-switch">
                                <input checked={state.isFavorite} title={`${t('favorite')}`} type="checkbox" className="custom-control-input" id="customSwitch1" />
                                <label onClick={toggleFavorite} className="custom-control-label" >{`${t('favorite')}`}</label>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-5 text-left">
                            {`${t('reference')}`}: <span className="text-success">{`${car.car_id}`}</span>
                        </div>
                        <div className="offset-2 col-5 text-right">
                            {!isMobile && <span>{`${t('location')}`}:</span>} {`${car.location}`}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>

}
// LOAD REDUX DATA AS PROPS
const mapStateToProps = (state: any) => {
    return {
        locations: state.locations,
        loggedUser: state.loggedUser,
        fuelTypes: state.fuelTypes,
        carTypes: state.carTypes
    }
}
export const CarReference = connect(mapStateToProps)(CarReferenceInternal);