import React, { useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import '../../css/header.scss';
import HeaderLogo from '../../img/header-logo.png';
import { LINKS } from '../../links';
import { LoggedUser } from '../../redux/LoggedUser';
import { User } from '.';

interface HeaderProps {
  loggedUser: LoggedUser
  bannerText: string
}


const HeaderInternal = ({ loggedUser, bannerText }: HeaderProps) => {
  const { t } = useTranslation(['labels']);

  const color = window.location.href.indexOf("color=") > 0 ? '#' + window.location.href.substring(window.location.href.indexOf("color=") + 6) : undefined;

  useEffect(() => {
    // hambuger/user animation
    const account = document.querySelector('.account-button');
    const navMenu = document.querySelector('.user-menu')

    account?.addEventListener('click', () => {
      navMenu?.classList.toggle('active');
    });
  }, []);

  return <>
    <div className="container-fluid sticky-top bg-white">
    <header className="wrapper mx-auto border-bottom">
      <nav className="navbar">
        <Link to={LINKS.HOME} title={t('description')} className="nav-branding"><img src={HeaderLogo} title={t('description')} height={50} /></Link>
        <div className="hidden-menu user-menu border">
          <User />
        </div>
        <div className="account-button">
          <div className="pr-2">{loggedUser && <b title={t('hello') + ' ' + loggedUser.name}>{t('hello') + ' ' + (loggedUser.name ? loggedUser.name : loggedUser.email)}&nbsp;</b>}
            <i className={`fa fa-user${(loggedUser ? " text-success" : "")}`} aria-hidden="true"></i>
          </div>
        </div>
      </nav>

    </header>
    </div>

    <div className="wrapper mx-auto content">
        <h1 className="fw-bold" title={`${bannerText ? t(bannerText) : t('cars')}`}>{`${bannerText ? t(bannerText) : t('cars')}`}</h1>
      </div>
  </>
}
// LOAD REDUX DATA AS PROPS
const mapStateToProps = (state: any) => {
  return {
    loggedUser: state.loggedUser
  }
}
export const Header = connect(mapStateToProps)(HeaderInternal);


