
import React, { ReactElement, useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import '../../css/car.scss';
import { API } from '../../links';
import { ICar } from '../../models/car';
import { IImage } from '../../models/image';

interface CarImagesProps {
    car: ICar,
}

interface CarImagesState {
    allImages: Array<ReactElement>,
    visibleIndexes: Array<number>
}

const CarImagesInternal = ({ car }: CarImagesProps) => {
    const { t } = useTranslation(['labels']);
    let NO_OF_SHOWN_IMAGES = isMobile ? 3 : 4;
    const [state, setState] = useState<CarImagesState>({
        allImages: car.images.map<ReactElement>((image: IImage, index: number) =>
         <a data-lightbox={`car-${car.car_id}`} href={`${API.IMAGE}carId=${car.car_id}&fileName=${image.name}`}><img id={`${car.car_id}-${index}`}  className={`img-thumb-list-${isMobile?'3':'4'} d-none`} src={`${API.IMAGE}carId=${image.folder_id}&fileName=${image.name}`} /></a>),
        visibleIndexes: [0, 1, 2, 3]
    })

    const nextImage = (backwardsDirection: boolean = false) => {
        console.time();
        let imgs: Array<Element> = Array.from(document.querySelectorAll(`[id^='${car.car_id}-']`));
        // get current visible indexes
        let visibleImgs: Array<Element> = Array.from(imgs.filter((img: Element) => !img.classList.contains('d-none')));
        let currentFirstIndex: number = imgs.indexOf(visibleImgs[0]);
        let currentLastIndex: number = imgs.indexOf(visibleImgs[visibleImgs.length - 1]);

        if (backwardsDirection) {
            // if we are currently at the first element, just dont...
            if (currentFirstIndex === 0) {
                return; // nothing to do
            }
            if (currentLastIndex === (imgs.length - 1) && visibleImgs.length < imgs.length) {
                // show next button since we shift the visible images left
                document.getElementById('#next-img')?.classList.remove('arrow-button-disabled');
                document.getElementById('#next-img')?.classList.replace('text-secondary', 'text-primary');
            }
            // hide last visible element
            visibleImgs.slice(-1)?.[0].classList.add('d-none');
            // show previous image
            imgs[--currentFirstIndex].classList.remove('d-none');
            // disable previous image button if we reached the begining
            if (currentFirstIndex === 0) {
                document.getElementById('#prev-img')?.classList.add('arrow-button-disabled');
                document.getElementById('#prev-img')?.classList.replace('text-primary', 'text-secondary');
            }
        } else {
            // if we are currently at the end, just dont...
            if (currentLastIndex === (imgs.length - 1)) {
                return; // nothing to do
            }
            if (currentFirstIndex === 0 && visibleImgs.length < imgs.length) {
                // show prev button
                document.getElementById('#prev-img')?.classList.remove('arrow-button-disabled');
                document.getElementById('#prev-img')?.classList.replace('text-secondary', 'text-primary');
            }
            // hide first visible element
            visibleImgs[0]?.classList.add('d-none');
            // show next image
            imgs[++currentLastIndex].classList.remove('d-none');
            // disable next image button if we reached the begining
            if (currentLastIndex === (imgs.length - 1)) {
                document.getElementById('#next-img')?.classList.add('arrow-button-disabled');
                document.getElementById('#next-img')?.classList.replace('text-primary', 'text-secondary');
            }
        }
        console.timeEnd();
    }

    useEffect(() => {
        let allImages = car.images.map<ReactElement>((image: IImage, index: number) =>
         <a data-lightbox={`car-${car.car_id}`} href={`${API.IMAGE}carId=${car.car_id}&fileName=${image.name}`}><img id={`${car.car_id}-${index}`}  className={`img-thumb-list-${isMobile?'3':'4'} d-none`} src={`${API.IMAGE}carId=${image.folder_id}&fileName=${image.name}`} /></a>);
        setState({...state, allImages});
        // set images to visible
        let imgs: Array<Element> = Array.from(document.querySelectorAll(`[id^='${car.car_id}-']`));
        if (imgs.length < NO_OF_SHOWN_IMAGES) {
            NO_OF_SHOWN_IMAGES = imgs.length;
        }
        Array<number>(NO_OF_SHOWN_IMAGES).fill(1).map((el: number, index: number) => index).forEach(index => {
            imgs[index].classList.remove('d-none');
            
        })
        // disable previous image button
        document.getElementById('#prev-img')?.classList.add('arrow-button-disabled');
        if (car.images.length <= NO_OF_SHOWN_IMAGES) {
            // also disable next
            document.getElementById('#next-img')?.classList.add('arrow-button-disabled');
        }
    }, [car])

    const carContainerClass = isMobile ? "col my-2" : "col-7 my-2 mx-auto py-2 border-top border-3 border-primary";
    return <>
        <div className="row">
            <div className={carContainerClass}>
                <div className="row">
                    <div className="col-auto d-flex align-items-center">
                        <i id="#prev-img" className="fa-solid fa-circle-arrow-left arrow-button-disabled text-secondary" onClick={() => nextImage(true)}></i>
                    </div>
                    <div className="col-9">
                        {state.allImages.map((image: ReactElement) => image)}
                    </div>
                    <div className="col-auto d-flex align-items-center">
                        <i id="#next-img" className="fa-solid fa-circle-arrow-right text-primary" onClick={() => nextImage()}></i>
                    </div>
                </div>
            </div>
        </div>
    </>

}
// LOAD REDUX DATA AS PROPS
const mapStateToProps = (state: any) => {
    return {
        locations: state.locations,
        loggedUser: state.loggedUser,
        fuelTypes: state.fuelTypes,
        carTypes: state.carTypes
    }
}
export const CarImages = connect(mapStateToProps)(CarImagesInternal);