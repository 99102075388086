import React, { useEffect, useState } from 'react';
import { ICar } from '../../models/car';
import { Car } from '.';

interface SearchResultsState {
    searchResults: Array<ICar>;
}

export const SearchResults = ({ searchResults } : SearchResultsState) => {

    const [state, setState] = useState<SearchResultsState>({
        searchResults: [],
    });

    useEffect(() => {
        // reload when search results change
        setState({ ...state, searchResults })
    }, [searchResults])

    return <div className="wrapper mx-auto" >
        {state.searchResults?.map(car =>
            <Car car={car} />
        )}
    </div>

}
