import { t } from 'i18next';
import React from 'react';
import { connect } from 'react-redux';
import '../../css/user.scss';
import { LoggedUser } from '../../redux/LoggedUser';
import { UserLogin } from './UserLogin';
import { UserMenu } from './UserMenu';

interface UserSectionProps {
    loggedUser: LoggedUser
}

const UserInternal = ({ loggedUser }: UserSectionProps) => {

    return <>
        <div className="user-container">
            <div className="row">
                <div className="col">
                    <h3>{`${(loggedUser ? t('profile') : t('not_logged_in'))}`}</h3>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    {loggedUser ? <UserMenu /> : <UserLogin />}
                </div>
            </div>
        </div>
    </>
}
// LOAD REDUX DATA AS PROPS
const mapStateToProps = (state: any) => {
    return {
        loggedUser: state.loggedUser
    }
}
export const User = connect(mapStateToProps)(UserInternal);
